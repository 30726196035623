<template>
  <div class="app-container oderAdUpload-container">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" label-width="100px" inline style="margin-top:20px;position: relative;">
      <el-form-item label="选择车场">
        <el-select v-model="searchForm.comId" @change="getAdType">
          <el-option :label="item.companyName" :value="item.id" v-for="(item,i) in parkList" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="广告状态" >
        <el-select v-model="searchForm.isSend" @change="getAdType">
          <el-option label="已下发" :value="true"></el-option>
          <el-option label="审核中" :value="false"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <!--上传广告-->
    <el-card class="ad-card" >
      <div style="display:flex;width:100%;">
        <div v-if="adType == 'IST' || adType == 'OFF'">
          <div class="ad-box" >
            <div style="width:18vw;">
              <div>首页弹窗广告上传
                <span class="Hyperlink" @click="setHyperlink('jumpUrl1')" :title="jumpUrl1 ? jumpUrl1 : '点击设置超链接'">超链接</span></div>
              <div style="margin-top:20px;" class="disabled-uploader">
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  list-type="picture-card"
                  :on-change="changeIndexPopImageUrl"
                  :on-remove="removeIndexPopImageUrl"
                  :file-list="indexPopImageUrl"
                  :limit="1"
                  :disabled="true"
                  :auto-upload="false">
                              
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </div>

            <div  style="width:18vw;">
              <div>首页banner广告上传 
                <span class="Hyperlink" @click="setHyperlink('jumpUrl2')" :title="jumpUrl2 ? jumpUrl2 : '点击设置超链接'">超链接</span>
                <span class="seeView" @click="previewPicture('1')" title="预览">预览</span></div>
              <div style="margin-top:20px;">
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  list-type="picture-card"
                  :on-change="changeIndexBannerImageUrl"
                  :on-remove="removeIndexBannerImageUrl"
                  :file-list="indexBannerUrl"
                  :limit="1"
                  :auto-upload="false">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </div>

            
          </div>

          <div class="ad-box" style="margin-top:20px;">
            <div  style="width:18vw;">
              <div>订单页面广告上传 
                <span class="Hyperlink" @click="setHyperlink('jumpUrl3')" :title="jumpUrl3 ? jumpUrl3 : '点击设置超链接'">超链接</span>
                <span class="seeView" @click="previewPicture('2')" title="预览">预览</span></div>
              <div style="margin-top:20px;">
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  list-type="picture-card"
                  :on-change="changeOderAdImageUrl"
                  :on-remove="removeOderAdImageUrl"
                  :file-list="OderAdImageUrl"
                  :limit="1"
                  :auto-upload="false">
                              
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </div>
            <div  style="width:18vw;" class="disabled-uploader">
              <div>支付结果广告上传<span class="Hyperlink" @click="setHyperlink('jumpUrl4')" :title="jumpUrl4 ? jumpUrl4 : '点击设置超链接'">超链接</span></div>
              <div style="margin-top:20px;">
                <el-upload
                  class="avatar-uploader "
                  action="#"
                  list-type="picture-card"
                  :on-change="changePayResultImageUrl"
                  :on-remove="removePayResultImageUrl"
                  :file-list="payImageUrl"
                  :limit="1"
                  :disabled="true"
                  :auto-upload="false">
                              
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </div>
            
          </div>

          <div class="ad-box"  style="margin-top:20px;"> 
            <div  style="width:18vw;">
              <div>无牌车扫码入场广告<span class="Hyperlink" @click="setHyperlink('jumpUrl5')" :title="jumpUrl5 ? jumpUrl5 : '点击设置超链接'">超链接</span></div>
              <div style="margin-top:20px;">
                <el-upload
                  class="avatar-uploader "
                  action="#"
                  list-type="picture-card"
                  :on-change="changenoNumImageUrl"
                  :on-remove="removenoNumImageUrl"
                  :file-list="noNumImageUrl"
                  :limit="1"
                  :auto-upload="false">
                              
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </div>

            <div  style="width:18vw;">
              <div>非机动车扫码入场广告<span class="Hyperlink" @click="setHyperlink('jumpUrl6')" :title="jumpUrl6 ? jumpUrl6 : '点击设置超链接'">超链接</span></div>
              <div style="margin-top:20px;">
                <el-upload
                  class="avatar-uploader "
                  action="#"
                  list-type="picture-card"
                  :on-change="changenonMotorImageUrl"
                  :on-remove="removenonMotorImageUrl"
                  :file-list="nonMotorImageUrl"
                  :limit="1"
                  :auto-upload="false">
                              
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </div>
          </div>
          <div style="margin-top:20px;">
            <div>建议上传大小:</div>
            <div style="margin-top:10px;">首页弹窗:750像素(宽) * 1150像素(高)</div>
            <div style="margin-top:10px;">其他图片:1125像素(宽) * 350像素(高)</div>
          </div>

        </div>
        
        <div v-if="adType == 'IST' || adType == 'OFF'">
          <div class="ad-box" style="width:12vw;">

            <div  style="width:12vw;">
              <div>非机动车扫码支付广告 
                <span class="Hyperlink" @click="setHyperlink('jumpUrl7')" :title="jumpUrl7 ? jumpUrl7 : '点击设置超链接'">超链接</span>
                <!-- <span class="seeView" @click="previewPicture('7')" title="预览">预览</span> -->
              </div>
              <div style="margin-top:20px;">
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  list-type="picture-card"
                  :on-change="changeNonMotorPayUrl"
                  :on-remove="removeNonMotorPayUrl"
                  :file-list="NonMotorPayUrl"
                  :limit="1"
                  :auto-upload="false">
                                
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </div>

            
          </div>
        </div>
        <div class="park-list" style="margin-left:30px;" v-if="adType == 'IST' || adType == 'OFF'">
          <div>效果预览:</div>
          <div class="like-phone">
            <orderPayPage :previewUrl="previewUrl" v-if="previewType == '1'"></orderPayPage> 
            <carInputPage :previewUrl="previewUrl" v-if="previewType == '2'"></carInputPage>    
          </div>
        </div>
        <div class="park-list" style="margin-left:30px;" v-if="adType === 'PP'">
          <div>效果预览:</div>
          <div class="like-phone">
            <orderPayPage previewUrl="https://file.sciseetech.com/file/images/add.jpg"></orderPayPage> 
          </div>
        </div>
        <div class="park-list">
          <div>选择要上传的车场</div>
          <el-checkbox-group v-model="comIdList" class="park-checkbox-group">
            <el-checkbox v-for="(item,i) in parkList" :key="i" :label="item.id">{{item.companyName}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <!-- <div class="ad-btn" v-if="!searchForm.isSend"> -->
      <div class="ad-btn" >
        <el-button type="primary" @click="Upload">上传</el-button>
      </div>
    </el-card>   

    <el-dialog title="上传进度" :visible.sync="dialogVisible" height="60%" width="40%">
      <div v-show="doNext">
        <div v-for="(item,i) in progressData" :key="i">
          <div style="margin:10px 0;">{{i}}</div>
          <el-progress
            :text-inside="true"
            :stroke-width="15"
            :percentage="item.progress"
            status="success"
          ></el-progress>
        </div>
      </div>
      <div v-show="!doNext">暂无图片上传,请直接应用</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendAdByComFun">应 用</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { getAuthCom,sts,client,sendAdByCom,getADInfoByUnion, getAdType, AdTypeList, defaultAdType} from "../../api/ad/oderAdUpLoad";
import orderPayPage from "@/components/ad/orderPayPage";
import carInputPage from "@/components/ad/carInputPage";
import Vue from 'vue';
export default {
  components: { orderPayPage,carInputPage},
  name: "index",
  data() {
    return {
      adTypeSetShow: false,
      comIdList: [],
      alidata: {},
      indexPopImageUrl: [],  //首页弹窗
      indexBannerUrl: [],  //首页banner
      OderAdImageUrl: [],  //订单页面
      payImageUrl: [],  //支付成功
      noNumImageUrl: [],  //无牌车
      nonMotorImageUrl: [],  //非机动车
      NonMotorPayUrl: [],  //非机动车支付页面
      indexPopImage: {},  //首页弹窗
      indexBanner: {},  //首页banner
      OderAdImage: {},  //订单页面
      payImage: {},  //支付成功
      noNumImage: {},  //无牌车
      nonMotorImage: {},  //非机动车
      NonMotorPay: {}, // 非机动车支付页面
      searchForm: {
        comId:'',
        isSend:false
      },
      //////////////////////////
      parkList: [],
      actions: 'http://192.168.1.196:9001/system/ad/upload',
      progressData: {},
      param: {},
      dialogVisible: false,
      imageName: [],
      parkData: {},
      upNum: 0,
      doNext: false,
      timestamp: null,
      previewUrl: '',
      previewType: '0',
      jumpUrl1: '',
      jumpUrl2: '',
      jumpUrl3: '',
      jumpUrl4: '',
      jumpUrl5: '',
      jumpUrl6: '',
      jumpUrl7: '',
      adType: '',
      adTypeList: []
    };
  },
  created() {
    const token = this.getCookie("yunying-auth-token")
    this.getparkList()
    this.AdTypeList()
    
  },
  mounted(){
    let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
    // if(div[0]){
    //    div[0].style.display = 'none' 
    // }
  },
  methods: {
    // 设置默认广告
    defaultAdType() {

    },
    // 获取所有广告类型
    AdTypeList() {
       AdTypeList().then(res=>{
        this.adTypeList = res
      })
    },
    // 获取广告类型
    getAdType() {
      getAdType(this.searchForm.comId,this.searchForm.isSend).then(res=>{
        this.adType = res.adType
        if (this.adType === 'IST' || this.adType === 'OFF') {
          this.getAdList()
        } else {
          // this.getAdListPP()
        }
      })
    },
    //设置超链接
    setHyperlink(url) {
      this.$prompt('请输入超链接URL', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          if (value && value !== null) {
            this[url] = value
          }
        }).catch(() => {
          this[url] = ''      
        });
    },
    //图片预览
    previewPicture(type){
      this.previewType = type;
      //首页banner广告
      if(type == '1'){
        if(this.indexBannerUrl.length){
          this.previewUrl = this.indexBannerUrl[0].url;
        }else{
          this.previewUrl = this.indexBanner.url ? this.indexBanner.url : ''
        }
      }
      if(type == '2'){
        if(this.OderAdImageUrl.length){
          this.previewUrl = this.OderAdImageUrl[0].url;
        }else{
          this.previewUrl = this.OderAdImage.url ? this.OderAdImage.url : ''
        }
      }
    },
    //获取选中车场已上传的广告
    getAdList(){
      this.timestamp = Date.parse(new Date());
      this.comIdList = []
      this.indexPopImageUrl = []
      this.indexBannerUrl = []
      this.OderAdImageUrl = []
      this.payImageUrl = []
      this.nonMotorImageUrl = []
      this.noNumImageUrl = []
      this.NonMotorPayUrl = []
      getADInfoByUnion(this.searchForm.comId,this.searchForm.isSend).then(res=>{
        this.comIdList.push(this.searchForm.comId)
        let area1 = false;
        let area2 = false;
        let area3 = false;
        let area4 = false;
        let area5 = false;
        let area6 = false;
        let area7 = false;
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        res.map((item,i)=>{
          this['jumpUrl' + item.area] = item.jumpUrl
          if(item.area == 1){
            this.handleImgToBase64(item.url,this.timestamp + 'indexPop', (image) => {
                // 接收回调结果
                this.indexPopImage = {
                  name: this.timestamp + 'indexPop',
                  percentage: 0,
                  raw: image,
                  size: image.size,
                };
            });
            this.indexPopImageUrl =  [{name: this.timestamp + 'indexPop', url: item.url}]
            div[0].style.display = 'none'
            area1 = true
          }
          if(item.area == 2){
            var timestamp = Date.parse(new Date());
            this.handleImgToBase64(item.url,this.timestamp+'indexBanner', (image) => {
                // 接收回调结果
                this.indexBanner = {
                  name: this.timestamp+'indexBanner',
                  percentage: 0,
                  raw: image,
                  size: image.size,
                }
            });
            this.indexBannerUrl =  [{name: this.timestamp + 'indexBanner', url: item.url}]
            div[1].style.display = 'none'
            area2 = true;
          }
          if(item.area == 3){
            var timestamp = Date.parse(new Date());
            this.handleImgToBase64(item.url,this.timestamp+'oderAd', (image) => {
                // 接收回调结果
                this.OderAdImage = {
                  name: this.timestamp+ 'oderAd',
                  percentage: 0,
                  raw: image,
                  size: image.size,
                };
            });
            this.OderAdImageUrl =  [{name: this.timestamp + 'oderAd', url: item.url}]
            div[2].style.display = 'none'
            area3 = true;
          }
          if(item.area == 4){
            var timestamp = Date.parse(new Date());
            this.handleImgToBase64(item.url,this.timestamp+'payAd' , (image) => {
                // 接收回调结果
                this.payImage = {
                  name: this.timestamp+'payAd',
                  percentage: 0,
                  raw: image,
                  size: image.size,
                };
            });
            this.payImageUrl =  [{name: this.timestamp + 'payAd', url: item.url}]
            div[3].style.display = 'none'
            area4 = true;
          }
          if(item.area == 5){
            var timestamp = Date.parse(new Date());
            this.handleImgToBase64(item.url,this.timestamp+'noNum' , (image) => {
                // 接收回调结果
                this.noNumImage = {
                  name: this.timestamp+'noNum',
                  percentage: 0,
                  raw: image,
                  size: image.size,
                };
            });
            this.noNumImageUrl =  [{name: this.timestamp + 'noNum', url: item.url}]
            div[4].style.display = 'none'
            area5 = true;
          }
          if(item.area == 6){
            var timestamp = Date.parse(new Date());
            this.handleImgToBase64(item.url,this.timestamp+'nonMotor' , (image) => {
                // 接收回调结果
                this.nonMotorImage = {
                  name: this.timestamp+'nonMotor',
                  percentage: 0,
                  raw: image,
                  size: image.size,
                };
            });
            this.nonMotorImageUrl =  [{name: this.timestamp + 'nonMotor', url: item.url}]
            div[5].style.display = 'none'
            area6 = true;
          }
          if(item.area == 7){
            this.handleImgToBase64(item.url,this.timestamp + 'nonMotorPay' , (image) => {
                // 接收回调结果
                this.NonMotorPay = {
                  name: this.timestamp + 'nonMotorPay',
                  percentage: 0,
                  raw: image,
                  size: image.size,
                };
            });
            this.NonMotorPayUrl =  [{name: this.timestamp + 'nonMotorPay', url: item.url}]
            div[6].style.display = 'none'
            area7 = true;
          }
          if(i == res.length - 1){
            if(!area1){
               div[0].style.display = 'inline-block' 
            }
            if(!area2){
               div[1].style.display = 'inline-block' 
            }
            if(!area3){
               div[2].style.display = 'inline-block' 
            }
            if(!area4){
               div[3].style.display = 'inline-block' 
            }
            if(!area5){
               div[4].style.display = 'inline-block' 
            }
            if(!area6){
               div[5].style.display = 'inline-block' 
            }
            if(!area7){
               div[6].style.display = 'inline-block' 
            }
          }
        })
        if(res.length == 0){
          div[0].style.display = 'inline-block' 
          div[1].style.display = 'inline-block' 
          div[2].style.display = 'inline-block' 
          div[3].style.display = 'inline-block' 
          div[4].style.display = 'inline-block' 
          div[5].style.display = 'inline-block' 
          div[6].style.display = 'inline-block' 
        }
      })
    },
    handleImgToBase64(url,fileName,cb) {
      let that = this;
      var image = new Image();
      image.crossOrigin = '';
      image.src = url;
      image.onload = function () {
        let base64 = that.imageToBase64(image); //图片转base64

        let file = that.base64ToFile(base64, fileName); //base64转File
			
        // 根据自身需求调整【因个人项目逻辑不一样，这里使用回调函数】
        cb && typeof cb == 'function' && cb(file);
        return file;
      }
    },
    //图片转为base64格式
    imageToBase64(img){
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
      var dataURL = canvas.toDataURL("image/jpeg" + ext);
      return dataURL;
    },
    //base64格式转为File文件类型
    base64ToFile(urlData, fileName){
      let arr = urlData.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--) {
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia], fileName, { type: mime });
    },
    //首页弹窗广告图片添加监听
    async changeIndexPopImageUrl(file, fileList){
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        div[0].style.display = 'none'
        this.indexPopImageUrl = [{name: this.timestamp + file.name,url:file.url}];
        file.name = this.timestamp + file.name
        this.indexPopImage = file;
    },

    //首页弹窗广告图片移除
    removeIndexPopImageUrl(file, fileList){
        this.indexPopImageUrl = [];
        this.indexPopImage = {};
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        setTimeout(()=>{
            div[0].style.display = 'inline-block'
        },1000)
    },
    //无牌车广告图片添加监听
    changenoNumImageUrl(file, fileList){
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        div[4].style.display = 'none'
        this.noNumImageUrl = [{name: this.timestamp + file.name,url:file.url}];
        file.name = this.timestamp + file.name
        this.noNumImage = file;
      
    },

    //无牌车窗广告图片移除
    removenoNumImageUrl(file, fileList){
        this.noNumImageUrl = [];
        this.noNumImage = {};
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        setTimeout(()=>{
            div[4].style.display = 'inline-block'
        },1000)
    },

    //非机动车广告图片添加监听
    changenonMotorImageUrl(file, fileList){
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        div[5].style.display = 'none'
        this.nonMotorImageUrl = [{name: this.timestamp + file.name,url:file.url}];
        file.name = this.timestamp + file.name
        this.nonMotorImage = file;
      
    },


    //非机动车支付广告图片移除
    removenonMotorImageUrl(file, fileList){
        this.nonMotorImageUrl = [];
        this.nonMotorImage = {};
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        setTimeout(()=>{
            div[5].style.display = 'inline-block'
        },1000)
    },

    //非机动车支付广告图片添加监听
    changeNonMotorPayUrl(file, fileList){
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        div[6].style.display = 'none'
        this.NonMotorPayUrl = [{name: this.timestamp + file.name,url:file.url}];
        file.name = this.timestamp + file.name
        this.NonMotorPay = file;
      
    },

    //非机动车广告图片移除
    removeNonMotorPayUrl(file, fileList){
        this.NonMotorPayUrl = [];
        this.NonMotorPay = {};
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        setTimeout(()=>{
            div[6].style.display = 'inline-block'
        },1000)
    },

    //首页Banner广告图片添加监听
    changeIndexBannerImageUrl(file, fileList){
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        div[1].style.display = 'none'
        this.indexBannerUrl = [{name:this.timestamp + file.name,url:file.url}];
        file.name = this.timestamp + file.name
        this.indexBanner = file;
        console.log(this.indexBanner)
    },
    
    //首页Banner广告图片移除
    removeIndexBannerImageUrl(file, fileList){
        this.indexBannerUrl = [];
        this.indexBanner = {};
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        setTimeout(()=>{
            div[1].style.display = 'inline-block'
        },1000)
    },
    //订单页面广告图片添加监听
    changeOderAdImageUrl(file, fileList){
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        div[2].style.display = 'none'
        this.OderAdImageUrl = [{name:this.timestamp + file.name,url:file.url}];
        file.name = this.timestamp + file.name
        this.OderAdImage = file;
    },
    
    //订单页面广告图片移除
    removeOderAdImageUrl(file, fileList){
        this.OderAdImageUrl = [];
        this.OderAdImage = {};
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        setTimeout(()=>{
            div[2].style.display = 'inline-block'
        },1000)
    },
    //支付结果广告图片添加监听
    changePayResultImageUrl(file, fileList){
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        div[3].style.display = 'none'
        this.payImageUrl = [{name:this.timestamp + file.name,url:file.url}];
        file.name = this.timestamp + file.name
        this.payImage = file;
    },
    
    //支付结果广告图片移除
    removePayResultImageUrl(file, fileList){
        this.payImageUrl = [];
        this.payImage = {};
        let div = document.querySelectorAll("div[class='el-upload el-upload--picture-card']")
        setTimeout(()=>{
            div[3].style.display = 'inline-block'
        },1000)
    },
    //获取能操作广告管理的车场集合
    getparkList(){
       let _this = this;
      
       getAuthCom()
        .then(records => {
          if(records && records.length){
            this.parkList = records;
            this.searchForm.comId = this.parkList[0].id;
            this.comIdList.push(this.parkList[0].id)
            this.parkList.map(item=>{
              this.parkData[item.id] = item.companyName
            })
            
            this.getAdType()
          }
        })
        .catch(e => {
           _this.$tip.apiFail(e)
        })
        .finally(() => {
          
        })
    },
    //上传广告
    Upload(){
        let that = this;
        this.dialogVisible = true;
        this.imageName = [];
        this.doNext = false;
        if(this.indexPopImageUrl.length){
            this.imageName.push({area:1,fileName:that.indexPopImageUrl[0].name,uploadTime:new Date().getTime(), url: that.jumpUrl1})
        }
        if(this.indexBannerUrl.length){
            this.imageName.push({area:2,fileName:that.indexBannerUrl[0].name,uploadTime:new Date().getTime(), url: that.jumpUrl2})
        }
        if(this.OderAdImageUrl.length){
            this.imageName.push({area:3,fileName:that.OderAdImageUrl[0].name,uploadTime:new Date().getTime(), url: that.jumpUrl3})
        }
        if(this.payImageUrl.length){
            this.imageName.push({area:4,fileName:that.payImageUrl[0].name,uploadTime:new Date().getTime(), url: that.jumpUrl4})
        }
        if(this.noNumImageUrl.length){
            this.imageName.push({area:5,fileName:that.noNumImageUrl[0].name,uploadTime:new Date().getTime(), url: that.jumpUrl5})
        }
        if(this.nonMotorImageUrl.length){
            this.imageName.push({area:6,fileName:that.nonMotorImageUrl[0].name,uploadTime:new Date().getTime(), url: that.jumpUrl6})
        }
        if(this.NonMotorPayUrl.length){
            this.imageName.push({area:7, fileName:that.NonMotorPayUrl[0].name,uploadTime:new Date().getTime(), url: that.jumpUrl7})
        }
        this.comIdList.map((item,i)=>{
            if(this.indexPopImageUrl.length && this.indexPopImage.name){
                this.doNext = true
                this.upImage(this.indexPopImage,item,`${this.parkData[item]}车场首页弹窗广告图片`)
            }
            if(this.indexBannerUrl.length && this.indexBanner.name){

                this.doNext = true
                this.upImage(this.indexBanner,item,`${this.parkData[item]}车场首页Banner广告图片`)
            }
            if(this.OderAdImageUrl.length && this.OderAdImage.name){
                this.doNext = true
                this.upImage(this.OderAdImage,item,`${this.parkData[item]}订单页广告图片`)
            }
            if(this.payImageUrl.length && this.payImage.name){
                this.doNext = true
                this.upImage(this.payImage,item,`${this.parkData[item]}支付完成广告图片`)
            }
            if(this.noNumImageUrl.length && this.noNumImage.name){
                this.doNext = true
                this.upImage(this.noNumImage,item,`${this.parkData[item]}无牌车扫码入场广告`)
            }
            if(this.nonMotorImageUrl.length && this.nonMotorImage.name){
                this.doNext = true
                this.upImage(this.nonMotorImage,item,`${this.parkData[item]}非机动车扫码入场广告`)
            }
            if(this.NonMotorPayUrl.length && this.NonMotorPay.name){
                this.doNext = true
                this.upImage(this.NonMotorPay,item,`${this.parkData[item]}非机动车支付页面广告`)
            }
        })
        
    },
    //上传图片
    upImage(file,item,type){
        let _this = this;
        sts().then(response => {
            //获取临时访问权限成功
            let obj = {}
            obj.accessKeyId = response.access_key_id;
            obj.accessKeySecret = response.access_key_secret;
            obj.securityToken = response.security_token;
            obj.bucket = response.bucket;
            obj.region = response.region;
            _this.alidata[type] = obj;
            
            //开始上传
            let temporary =file.name.lastIndexOf(".");
            let fileNameLength =file.name.length;
            // let fileFormat =file.name.substring(temporary + 1, fileNameLength);
            let fileName = file.name; //uuid文件名称
            let filePath = `order-ad/park/${item}/${fileName}`;
            //存放上传文件的参数
            const token = this.getCookie("yunying-auth-token")
            let paramobj = {}
            paramobj.token = token;
            paramobj.fileName = fileName;
            paramobj.filePath = filePath;
            paramobj.adType = 0;
            paramobj.comId = item;
            _this.param[type] = paramobj;
            client(_this.alidata[type])
                .multipartUpload(filePath, file.raw, {
                progress: function(p) {
                    //p进度条的值
                    let progressObj = {};
                    progressObj.show = true;
                    progressObj.progress = Math.floor(p * 100);
                    Vue.set(_this.progressData,type,progressObj)
                    _this.progressData[type] = progressObj;
                }
                })
                .then(result => {
                  console.log(result)
                //上传成功返回值，可针对项目需求写其他逻辑
                    _this.upNum += 1;
                    if(_this.upNum == _this.imageName.length * this.comIdList.length){
                        _this.$tip.apiSuccess('图片上传成功')
                    }
                })
                .catch(err => {
                    console.log("err:", err);
                });
        })
    },
    //广告应用
    sendAdByComFun(){
      let _this = this;
      let data = {
        "comId":this.comIdList,
        "data":this.imageName
      }
      sendAdByCom(data).then(res=>{
        this.dialogVisible = false;
        this.$message({ message: '操作成功', type: 'success'});
        this.getAdList()
      }).catch(e=>{
        this.dialogVisible = false;
        _this.$message.error('应用失败');
        
      })
    },
    getCookie(objName){//获取指定名称的cookie的值
      var arrStr = document.cookie.split("; ");
      for (var i = 0; i < arrStr.length; i++) {
        var temp = arrStr[i].split("=");
        if (temp[0] == objName){
          return decodeURI(temp[1]);
        }
      }
    },
  }
};
</script>

<style scoped>
  .oderAdUpload-container{
  font-size: 16px;
  }
  .ad-box{
    display: flex;
    width:28vw;
    justify-content:space-between;
  }
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    text-align: center;
  }
  .avatar {
    display: block;
  }
  .park-list{
    max-width:500px;
    margin-left:30px;
  }
  .ad-btn{
    text-align: right;
    box-sizing: border-box;
    padding-right:20px;
  }
  .like-phone{
    background-color: #f8f8f8;
    border-radius: 20px;
    width:360px;
    height:640px;
    margin-top:20px;
    overflow: hidden;
  }
  .seeView{
    cursor: pointer;
    color:#fff;
    text-align: center;
    padding:4px 6px;
    background:#67C23A;
    border-radius: 2px;
    font-size:12px;
    margin-left: 6px;
  }
  .Hyperlink {
    cursor: pointer;
    color:#fff;
    text-align: center;
    padding:4px 6px;
    background:#409EFF;
    border-radius: 2px;
    font-size:12px;
  }
</style>
<style>
  .ad-card{
    position:relative;
  }
  .park-checkbox-group .el-checkbox{
    /* width:160px; */
    margin:10px;
  }
  .disabled-uploader .el-upload--picture-card{ 
    cursor:not-allowed;
  }
  .disabled-uploader .el-upload--picture-card:hover{ 
    border-color: #c0ccda;
    color: #c0ccda;
  }
</style>
