<template>
  <div class="intercom">
    <!-- webSocket链接状态 -->
    <div class="wb-staus">
      <div v-if="wbStaus">webSocket已链接<i class="el-icon-success" style="color:#67C23A"></i></div>
      <div v-if="!wbStaus">webSocket已断开<i class="el-icon-error" style="color:#F56C6C"></i></div>
    </div>
    <!-- 左边开始 -->
    <div class="leftMoudle">
      <!-- 去处理 -->
      <div>
        <!-- 车场 -->
        <div class="parking_02">
          <div class="parking_05">
            <div>
              <div class="park_pass">车场列表</div>
              <div class="searchPark">
                <el-input v-model="parkname" @input="getParkData" placeholder="车场号/车场名搜索" prefix-icon="el-icon-search" />
              </div>
              <div class="parking_04">
                <div v-for="(item, i) in parkAll" :key="i">
                  <div
                    v-if="item.comPassList.length"
                    @click="getPass(item, i)" 
                    :class="selectParkId == item.id ? 'checked-park' : 'parking_03'"
                    style="height:11vh;min-height:110px;position: relative;"
                  >
                    <div class="park-state-icon" >
                      <i v-if="item.parkStatus != 1 && item.sourceType != 1" class="el-icon-error" style="color:#F56C6C;"></i>
                      <i v-if="item.parkStatus == 1 || item.sourceType == 1" class="el-icon-success" style="color:#67C23A;"></i>
                      <div v-if="item.parkStatus == 1 || item.sourceType == 1" style="color:#67C23A;">在线</div>
                      <div v-else style="color:#F56C6C;">离线</div>
                    </div>
                    <div class="park_01" style="margin-top: -30px;"  >
                      <div> 车场编号： <span>{{ item.id }}</span></div>
                      <div> 车场名称：
                        <el-tooltip class="item" effect="light" :content="item.companyName" placement="top">
                          <div>{{ item.companyName && item.companyName.length > 12 ? item.companyName.slice(0, 10) + "..." : item.companyName }}</div>
                        </el-tooltip>
                      </div>
                      <div style="margin-bottom:0px;"> 联系电话： <span>{{ item.mobile }}</span></div>
                      
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <!-- 通道 -->
            <div>
              <div class="park_pass">通道信息</div>
              <div class="searchPark">
                <el-input v-model="passid" @input="getPassData" placeholder="搜索通道名称" prefix-icon="el-icon-search"></el-input>
              </div>
              <div class="parking_04">
                <div class="parking_03" v-for="(item, i) in parkPass" :key="i" :class=" selectPass == item.id ? 'blue' : ''" @click="switchPass(item)">
                  <div class="park_01" style="padding:10px;position: relative;">
                    <div> 通道编号： <span>{{ item.id }}</span> </div>
                    <div style="margin-bottom: 10px"> 通道名称： <span>{{ item.passname }}</span> </div>
                    <div class="DList" @click.stop="getDeciveList(item.id)">设备列表</div>
                  </div>
                  <div class="microphone">
                  <!-- <div class="microphone" @click="switchPass(item)"> -->
                    <div class="btn-div" style="cursor:not-allowed;"  v-if="isCallTime">呼叫({{callTime}})</div>
                    <div :class="selectPass == item.id ? 'btn-div-yellow' : 'btn-div'" v-if="!isTalk && !isCallTime" @click.stop="activeCall(item)">呼叫</div>
                    <div class="btn-div-green" v-if="isTalk && selectPass == item.id && !isCallTime" @click.stop="breakCall(item)">挂断通话....</div>
                    
                  </div>
                  <div class="btn-div" style="cursor:not-allowed;" v-if="isTalk && selectPass != item.id && !isCallTime">呼叫</div>
                </div>
              </div>
            </div>    
            <!-- 通道结束 -->
          </div>
        </div>
      </div>
      <!-- 待处理 -->
      <div class="parking" style="margin-top: 10px">
        <div class="titles_02">
          <div class="titles">
            &nbsp;呼叫消息&nbsp;
            <span class="num">{{ total }}</span>
            <span>&nbsp;条</span>
          </div>
          <div class="titles">&nbsp;告警消息</div>
        </div>
        <!-- 表格数据 -->
        <div class="table">
          <!-- 待处理列表 -->
          <div>
            <div class="data" v-for="(item, i) in tableData" :key="i" style="height: 14vh;">
              <div>车场：
                <el-tooltip class="item" effect="light" :content="item.isTrtcCall ? item.comid : parkAllData[item.comid]" placement="top">
                  <span v-if="!item.isTrtcCall">{{
                    parkAllData[item.comid] && parkAllData[item.comid].length > 12
                      ? parkAllData[item.comid].slice(0, 12) + "..."
                      : parkAllData[item.comid]
                  }}</span>
                  <span v-if="item.isTrtcCall">{{item.comid}}</span>
                </el-tooltip>
              </div>
              <div>通道：<span>{{ item.pass_name }}</span></div>
              <div>时间：<span>{{ item.times }}</span></div>
              <div class="table-btn" style="text-align: right;top:10px;">
                <div class="btn-div" style="cursor:not-allowed;" v-if="isTalk">呼叫</div>
                <div v-if="!isTalk" @click="answerInformation(item,i)" class="btn-div" >{{item.outTimeCall ? '呼叫超时,点击清除' : '接听'}}</div>
              </div>
            </div>
          </div>
          <!-- 告警消息 -->
          <div>
            <div class="data" v-for="(item, i) in alarm_InFor" :key="i">
              <el-popover placement="top" width="400" trigger="click" popper-class="alarm-popper">
                <!-- 详情 -->
                <div class="infor">
                  <div>编号：<span>{{ item.id }}</span></div>
                  <div>车场：<span>{{ item.comid }}</span></div>
                  <div>通道：<span>{{ item.channelId }}</span></div>
                  <div>设备编号：<span>{{ item.iotEquipId }}</span></div>
                  <div>告警时间：<span>{{ $getTime(item.alarmTime) }}</span></div>
                  <div>告警内容：<span>{{ item.alarmMsg }}</span></div>
                  <div>级别：<span>{{ item.levels }}</span></div>
                  <div>描述：<span>{{ item.describe }}</span></div>
                </div>
                <!-- 详情 -->
                <div slot="reference" class="alarm">
                  <div>车场：<span>{{ item.comid }}</span></div>
                  <div>通道：<span>{{ item.channelId }}</span></div>
                  <div>告警内容：<span>{{ item.alarmMsg }}</span></div>
                  <div class="table-btn" style="text-align: right">
                    <el-button type="warning" size="mini" round @click.stop="confirmOperation(item)">处理</el-button>
                  </div>
                </div>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 左边结束 -->

    <!-- 右边开始 -->
    <div class="context">
      <div style="display: flex; justify-content: space-between;transform: scale(1.1,1.1);position: relative;left: 3vw;">
        <el-form :inline="true" :model="formInline">
          <el-form-item label="车场号:" style="margin-bottom: 10px" id="searchTitle">
            <div style="color: #D0E6F7;font-size: 1.8vh;font-weight: bold;width:120px;">{{selectParkId}}</div>
          </el-form-item>
          <el-form-item label="通道名称:" style="margin-bottom: 10px" id="searchTitle">
            <div style="color: #D0E6F7;font-size: 1.8vh;font-weight: bold;width:120px;">{{selectPassData.passname}}</div>
          </el-form-item>
          <el-form-item label="车牌号" style="margin-bottom: 10px" id="searchTitle">
            <el-popover placement="top" v-model="visible6">
              <CustomKeyboard @input="keyBoardFn11" @blur="keyBoardFn11" :keyboardStart="keyboardStart11" />
              <el-input slot="reference" v-model="formInline.car_number" @blur="keyBoardFn111" placeholder="车牌号" prefix-icon="el-icon-search"></el-input>
            </el-popover>
          </el-form-item>
          <el-form-item style="margin-bottom: 10px">
            <div @click="searchData" class="btn-div">
              <i class="el-icon-search"></i>搜索
            </div>
          </el-form-item>
        </el-form>
      </div>

    
      
      <!-- 图片 -->
      <div class="order">
        <div class="park_pass">订单信息</div>
        <div class="park_detail_box">
          <div>
            <!-- 车牌信息 -->
            <div>
              <el-card shadow="hover" :body-style="{ padding: '10px',height:'11vh' }">
                <div class="park_card_header">
                  <div class="park_card_header_icon"></div>
                  <div>车牌信息</div>
                </div>
                <div style="height: 77px; overflow-y: scroll;width:25vw;" class="park_card_box">
                  <div class="car_number_box">
                    <div v-for="(item, i) in carNumberAll" :key="i" class="car_number_item" @click="getParkInformation(item)">
                      <div v-if="car_number !== item" class="no_checked"></div>
                      <div v-if="car_number == item" class="is_checked"></div>
                      <div>{{item}}</div>
                    </div>
                  </div>
                </div>
              </el-card>

            </div>

            <div class="parkInformation">
              <div class="car_detail">
                <!-- 会员信息 -->
                <div class="vip_card">
                  <div class="vip_card_header">
                    <div class="vip_card_header_icon"></div>
                    <div>会员信息</div>
                  </div>
                  <div class="park_10 vip_card_box">
                    <div>
                      <div>月卡编号：<span style="word-break: break-all;">{{ monthlyCard.cardId }}</span></div>
                      <div>套餐：<span>{{ monthlyCard.pid }}</span></div>
                    </div>
                    <div>
                      <div>车主姓名：<span>{{ monthlyCard.name }}</span></div>
                      <div>到期时间：<span>{{ monthlyCard.eTimes }}</span></div>
                    </div>
                  </div>
                </div>

                <!-- 车辆信息 -->
                <div class="car_card">
                  <div class="car_card_header">
                    <div class="car_card_header_icon"></div>
                    <div>订单信息</div>
                  </div>
                  <div class="park_10 vip_card_box">
                    <div>
                      <!-- <div class="change-carNumber" @click="editCarNumber">车牌号码：<span>{{ order.carNumber }}</span><i class="el-icon-edit"></i></div>  -->
                      <div class="change-carNumber" >车牌号码：<span>{{ order.carNumber }}</span></div>
                      <div>订单编号：<span>{{ order.orderIdLocal }}</span></div>
                      <div>入场时间：<span>{{ order.createTime }}</span></div>
                    </div>
                    <div>
                      <div>入场通道：<span>{{ order.inPassid }}</span></div>
                      <div>出场时间：<span>{{ order.eTime }}</span></div>
                      <div>出场通道：<span>{{ order.outPassid }}</span></div>
                    </div>
                  </div>
                  <div class="park_10 vip_card_box">
                    <div>
                      <div>优惠券使用：<span>{{ orderText }}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div>

            <div class="img">
              <div v-if="inPicURL">
                <el-image :src="inPicURL" id="inImg" style="width:100%;height:100%:" fit="cover" :preview-src-list="[inPicURL,outPicURL]">
                  <div slot="error" class="no_inPicURL">
                    <div style="color:#D0E6F7;line-height:18vh;font-size:2vh;text-align:center;">图片加载失败</div>
                  </div>
                </el-image>
                <!-- <img :src="inPicURL" id="inImg" @error="inImgError" /> -->
              </div>
              <div v-if="!inPicURL" class="no_inPicURL">
                <div class="no_data_div"></div>
                <div>暂无入场订单</div>
              </div>  
              <div v-if="outPicURL">
                <el-image :src="outPicURL" id="outImg" style="width:100%;height:100%:" fit="cover" :preview-src-list="[inPicURL,outPicURL]">
                  <div slot="error" class="no_inPicURL">
                    <div style="color:#D0E6F7;line-height:18vh;font-size:2vh;text-align:center;">图片加载失败</div>
                  </div>
                </el-image>
              </div>
              <div v-if="!outPicURL" class="no_outPicURL">
                <div class="no_data_div"></div>
                <div>暂无出场订单</div>
              </div>
              
            </div>
          </div>
        </div>
      </div>

        
      <!-- 视频 -->
      <div class="playVideo">
        <div>
          <div class="park_pass">通道视频</div>
          <div class="playVideo_02">
            <div class="no-video" v-show="!isTalk || falsePassViedo">
              <div class="no-video-img"></div>
              <div>暂无视频</div>
            </div>
            <div v-show="isTalk && !falsePassViedo" style="width:100%;height:100%;">
              <div  id="Players" 
                @dblclick="toFullScreen('Players')"
                style="width:100%;height:100%;"
                v-loading="isLoadingPlayers" 
                element-loading-text="视频加载中" 
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" ></div>
              </div>
            </div>
            
        </div>
        <div>
          <div class="park_pass">全景视频</div>
          <div class="playVideo_02">
            <div class="no-video" v-show="!isTalk">
              <div class="no-video-img"></div>
              <div>暂无视频</div>
            </div>
            <div v-show="isTalk " style="width:100%;" >
              <div id="videoContainer" 
                @dblclick="toFullScreen('videoContainer')"
                style="width:100%;height:40.4vh;" 
                v-loading="isLoadingvideoContainer"
                element-loading-text="视频加载中" 
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" ></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 快捷操作 -->
      <div class="playVideo1">
        <div style="width: 100%" class="playVideoBtn">
           <div @click="open(1)" :style="{cursor:selectPass ? 'pointer' : 'not-allowed'}" class="btn-div">关闸</div>
          <!-- <div @click="open(0)" :style="{cursor:selectPass ? 'pointer' : 'not-allowed'}" class="btn-div">开闸放行</div> -->
          <!-- <div @click="addMoney" class="btn-div">补单缴费</div> -->
          <div @click="specialVehicleTypeFun" :style="{cursor:selectPass ? 'pointer' : 'not-allowed'}" class="btn-div">开闸放行</div>
          <div @click="openInPark" :style="{cursor:(switchPark && switchPark.id) ? 'pointer' : 'not-allowed'}" class="btn-div">入场放行</div>
          <div @click="settlement" v-if="switchPark.sourceType == 2" :style="{cursor:(switchPark && switchPark.id) ? 'pointer' : 'not-allowed'}" class="btn-div">结算离场</div>
          <div @click="manualSetCarNumberShowFun" v-if="switchPark.sourceType == 1" :style="{ cursor:(switchPark && switchPark.id) ? 'pointer' : 'not-allowed'}" class="btn-div">结算离场</div>
        </div>
      </div>
    </div>
    <!-- 右边结束 -->
    <!-- 浮块 -->
    <div class="floatBlock" @mousedown="moveFloatBlock($event)" @mouseup="endDag" v-show="isfloat">
      <el-row :gutter="24" style="margin: 0; padding: 0">
        <el-col :span="24" style="margin: 0; padding: 0">
          <el-card shadow="hover" :body-style="{ padding: '10px', paddingTop: '5px', paddingRight: '5px', }">
            <div class="closeFloat">
              <i class="el-icon-close" style="font-size:1.8vh;color:#D0E6F7;"></i>
            </div>
            <div class="floatIn_02">
              <div class="floatIn">
                <div>车场名：<span>{{ company_name }}</span></div>
                <div>通道名：<span>{{ pass_name }}</span></div>
                <div>时间：<span>{{ time }}</span></div>
              </div>
            </div>
            <div style="text-align: center">
              <div @click="answer(item.isTrtcCall)" class="btn-div">接听</div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- 音频 -->
    <div class="floatBlock">
      <!-- <audio :src="audioUrl" class="audio" ref="audio" loop></audio> -->
      <audio class="audio"  loop>
        <source  ref="audio" :src="mp3url" >
      </audio>
    </div>
    <!-- 特殊车辆 -->
    <el-dialog title="开闸放行" :visible.sync="specialVehicleShow" custom-class="CarNumber-dialog" width="40vw" height="60vh">
      <div style="width: 22vw;margin: 0 auto;">
        <el-form :model="specialVehicleFrom" ref="form" label-width="100px">
          <el-form-item label="车辆类型" required>
            <el-select v-model="specialVehicleFrom.carType" style="height: 42px;line-height: 42px;">
              <el-option v-for="item in specialVehicleList" :label="item.label" :value="item.value" :key="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="拒绝支付金额" required v-if="specialVehicleFrom.carType == 14">
            <el-select v-model="specialVehicleFrom.refuseAmount">
              <el-option label="0元" :value="0" />
              <el-option label="5元" :value="5" />
              <el-option label="10元" :value="10" />
              <el-option label="15元" :value="15" />
              <el-option label="20元" :value="20" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="btn-box">
          <div @click="specialVehicleShow = false" class="btn-div cancel-btn">取 消</div>
          <div @click="specialVehicleFun" class="btn-div">确 定</div>
        </div>
      </div>
    </el-dialog>
    <!-- 弹框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogData" custom-class="CarNumber-dialog" width="60vw" height="60vh" @close="closeDialogTitle">
      <div style="width: 22vw;margin: 0 auto;">
        <div class="switchPark-name">{{`${switchPark.companyName}(${switchPark.id})`}}</div>
        <el-form :model="form" :rules="rules" ref="form" label-width="120px" class="big-form">
          <el-form-item label="选择通道" required>
            <el-select v-model="selectPassChannelId2">
              <el-option v-for="item in parkPass" :key="item.id" :label="item.passname" :value="item.channelId" />
            </el-select>
          </el-form-item>
          <el-form-item label="车牌号" prop="switchCarNumber" v-if="dialogTitle == '结算离场'">
            <el-popover placement="top"  v-model="visible8">
              <CustomKeyboard @input="keyBoardFn13" @blur="keyBoardFn13" :keyboardStart="keyboardStart13" />
              <el-input slot="reference" placeholder="请输入车牌号" v-model="form.switchCarNumber" @blur="keyBoardFn133" class="input-with-select" style="width: 215px">
                <el-button @click.stop="getcarNumList(form.switchCarNumber)" :disabled="form.switchCarNumber.length < 3" slot="append" style="height: 48px;background:rgba(15, 203, 235, 0.4);color: #2aebff;border-color:rgba(15, 196, 229, 0.54);" icon="el-icon-search"></el-button>
              </el-input>
            </el-popover>
          </el-form-item>
          <el-form-item label="车牌号" prop="switchCarNumber" v-if="dialogTitle == '入场放行'">
            <el-popover placement="top"  v-model="visible8">
              <CustomKeyboard @input="keyBoardFn13" @blur="keyBoardFn13" :keyboardStart="keyboardStart13" />
              <el-input slot="reference" placeholder="请输入车牌号" v-model="form.switchCarNumber" @blur="keyBoardFn133"   class="input-with-select" />
            </el-popover>
          </el-form-item>
          <div style=" overflow-y: scroll;width:25vw;" class="park_card_box" v-if="dialogTitle == '结算离场'">
            <div class="car_number_box" style="margin-left: 43px;margin-top: 14px;box-sizing: border-box;" v-if="carNumberAll2.length">
              <div v-for="(item, i) in carNumberAll2" :key="i" class="car_number_item" @click="form.switchCarNumber = item">
                <div v-if="form.switchCarNumber !== item" class="no_checked"></div>
                <div v-if="form.switchCarNumber == item" class="is_checked"></div>
                <div>{{item}}</div>
              </div>
            </div>
            <div style="margin-top: 14px;box-sizing: border-box;font-size: 20px;text-align: center;color: #D0E6F7;" v-if="!carNumberAll2.length">未匹配到在场车辆</div>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="btn-box">
          <div @click="closeDialogTitle" class="btn-div cancel-btn">取 消</div>
          <div @click="confirmSumbit('form')" class="btn-div">确 定</div>
        </div>
      </div>
    </el-dialog>
    <!-- 弹框结束 -->

    <!-- 设备列表 -->
    <el-dialog title="设备列表" :visible.sync="dialogTableVisible" custom-class="CarNumber-dialog">
      <el-table :data="DeviceTable">
        <el-table-column property="id" label="ID"></el-table-column>
        <el-table-column property="nikeName" label="设备名称"></el-table-column>
        <el-table-column property="nodeId" label="设备编号" min-width="160"></el-table-column>
        <el-table-column prop="status" label="设备状态">
          <template slot-scope="{row}">
            <div :style="{color:statucColor[row.status]}">{{deviceStatusObj[row.status]}}</div>
          </template>
        </el-table-column>
        <el-table-column property="address" label="操作" v-if="containPermissions(['system:device:restart'])">
          <template slot-scope="{row}">
            <el-button size="mini" type="success" @click="restartDevice(row)" v-permissions="['system:device:restart']">重启</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 手动识别 -->
    <el-dialog title="手动识别" :visible.sync="manualSetCarNumberShow" custom-class="CarNumber-dialog" width="22vw">
      <el-form :model="manualSetCarNumberFrom" ref="form" label-width="100px">
        <el-form-item label="车牌号" required>
          <el-popover placement="top" v-model="visible7">
            <CustomKeyboard @input="keyBoardFn12" @blur="keyBoardFn12" :keyboardStart="keyboardStart12" />
            <el-input slot="reference" v-model="manualSetCarNumberFrom.carNumber" @blur="keyBoardFn122" style="width:214px;" ></el-input>
          </el-popover>
          
        </el-form-item>
        <el-form-item label="车牌颜色" required>
          <el-select v-model="manualSetCarNumberFrom.plateColor">
            <el-option label="蓝" value="蓝" />
            <el-option label="绿" value="绿" />
            <el-option label="黄" value="黄" />
            <el-option label="黑" value="黑" />
            <el-option label="白" value="白" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div class="btn-box">
          <div @click="manualSetCarNumberShow = false" class="btn-div cancel-btn">取 消</div>
          <div @click="manualSetCarNumberFun" class="btn-div">确 定</div>
        </div>
      </div>
    </el-dialog>

    

    <!-- 挂断按钮 -->
    <div class="break-talk" @click.stop="breakCall()" v-if="isTalk">
      <i class="el-icon-phone" style="font-size:20px;color:#fff;"></i>
    </div>
    <remote-js src="https://pv.sohu.com/cityjson?ie=utf-8"></remote-js>

  </div>
</template>

<script>
import {
  queryParkCarNumber,
  webRTCConfig,
  queryMonthCardDetails,
  queryOrderDetails,
  queryDevice,
  queryParkList,
  queryPassData,
  queryPassDetails,
  queryParkPass,
  queryManagerComList,
  alarmQueryInfor,
  alarmOperateInfor,
  queryAllIotDeviceInfor,
  deviceRestart,
  getUSAndSI,
  notifyingUserJoin,
  getPassDatails,
  refreshToken,
  onOffGate,
  makeupOrderWithPay,
  settlementWithOut,
  inParkRelease,
  queryParkDetails,
  manualSetCarNumber,
  specialVehicleType,
  specialVehicle,
} from "@/api/work/work";
import { getToken } from "@/utils/auth"; // get token from cookie
import Item from "@/components/work/Item.vue";
import TRTC from 'trtc-js-sdk';
import { setToken } from "@/utils/auth"; // get token from cookie
import BasePage from '@/components/base/BasePage'
import CustomKeyboard from "@/components/CustomKeyboard/index.vue";
export default {
  name: "HelloWorld",
  extends: BasePage,
  components: {
    CustomKeyboard,
    Item,
    'remote-js': {
      render(createElement) {
        return createElement('script', { attrs: { type: 'text/javascript', src: this.src }});
      },
      props: {
        src: { type: String, required: true },
        
      },
    },
  },
  props: {
    msg: String,
  },
  data() {
    return {
      visible6: false,
      visible7: false,
      visible8: false,
      keyboardStart13: "",
      keyboardStart12: "",
      keyboardStart11: "",
      carNumList: [],
      getWebSocketNum: 0,
      mp3url: '',
      specialVehicleFrom: {
        carType: 1,
        refuseAmount: 0,
        comId: null,
        passId: null,
        type: 0
      },
      specialVehicleList: [],
      specialVehicleShow: false,
      manualSetCarNumberFrom: {
        carNumber: '',
        comId: null,
        passId: null,
        plateColor: '蓝'
      },
      manualSetCarNumberShow: false,
      wbStaus:false,    //websocket链接状态
      deviceStatusObj:{
        0 : '在线',
        1 : '离线',
        2 : '未激活',
        3 : '禁用',
      },
      statucColor:{
        0 : '#67C23A',
        1 : '#909399',
        2 : '#E6A23C',
        3 : '#F56C6C',
      },
      dialogTableVisible:false,
      selectPass:'',
      isTalk:false, //是否是呼叫状态
      tableData: [
        // {company_name:'1111111111111111',pass_name:'222222222',times:'2021-10-8'}
      ],
      isfloat: false, //是否显示电话模块浮块

      parkAll: [], //获取所有车场
      parkAllData:{},
      selectParkId:'',
      parkAlllist: [], //获取所有车场
      parkPass: [], //车场对应通道
      parkPasslist: [], //车场对应通道
      passid: "", //通道搜索
      parkname: "",
      selectParkData: "", //选中的车场
      formInline: {
        //车牌号搜索框
        car_number: "",
      },
      carNumberAll: [], //获取的所有车牌
      carNumberAll2: [],
      car_number: "", //

      inPicURL: "", //入场图片
      outPicURL: "", //车场图片
      order: {
        //订单信息
        carNumber: "",
        createTime: "",
        orderIdLocal: "",
        in_pass: "",
        out_pass: "",
        eTime: "",
      },
      monthlyCard: {
        //会员信息
        cardId: "",
        pid: "",
        name: "",
        eTime: "",
      }, //月卡
      parkDetails: {}, //车场信息
      channelData: {
        //通道信息
        channel_id: "",
        channel_name: "",
        channel_type: "",
      }, //通道信息
      channelType: {
        //
        0: "入口",
        1: "出口",
        2: "出入口",
      },
      panoramicCameraVideoUrl: "", //全景视频url
      noPic: "http://istparking.sciseetech.com/images/no_order_pic.jpg",

      webSocket: null,
      service_name: "", //登录成功时name
      ws_token: "", //登录成功凭证

      retData: {},
      short_id: "",
      reg: "", //用于区分呼叫上行
      passiveReg: "", //用于区分呼叫上行
      DeviceData: {},
      company_name: "",
      pass_name: "",
      time: "",
      total: 0,
      downCallmsg: {},
      switchItem: {},
      switchPark: {},

      dialogTitle: "",
      dialogData: false,
      form: {
        switchCarNumber: "", //弹框值
      },
      rules: {
        switchCarNumber: [
          { required: true, message: "请输入车牌号", trigger: "blur" },
        ],
      },

      // audioUrl: this.$audioUrl,
      audioUrl:'../../../static/yunyin_web.mp3',



      alarm_InFor: [
        // {id:'1',comid:'30222',channel_id:'channel_id',iot_equip_id:'iot_equip_id',alarm_times:'alarm_times',alarm_msg:'alarm_msg',levels:'levels',describe:'describe'},
        // {id:'1',comid:'30222',channel_id:'channel_id',iot_equip_id:'iot_equip_id',alarm_times:'alarm_times',alarm_msg:'alarm_msg',levels:'levels',describe:'describe'},
        // {id:'1',comid:'30222',channel_id:'channel_id',iot_equip_id:'iot_equip_id',alarm_times:'alarm_times',alarm_msg:'alarm_msg',levels:'levels',describe:'describe'},
        // {id:'1',comid:'30222',channel_id:'channel_id',iot_equip_id:'iot_equip_id',alarm_times:'alarm_times',alarm_msg:'alarm_msg',levels:'levels',describe:'describe'}
      ], //告警消息列表
      // 告警消息状态
      state: { 10001: "未处理", 10002: "已处理", 10003: "忽略", },
      // 告警消息level
      level: { 4001: "提示", 4002: "警告", 4003: "严重", 4004: "紧急", },
      ticketData: { 1:'分钟', 2:'小时', 3:'天', 4:'元', 5:'折', },
      ticketTypeValue:{
        0:'普通停车券',
				1:'专用停车券',
				2:'微信专用打折券',
				3:'定额时长券',
				4:'全免券',
        5:'定额金额劵',
				6:'折扣券',
				7:'车位抵扣券',
				8:'金额抵扣券',
        9:'优免时段券',
				10:'变额时长减免券',
				11:'变额金额减免券',
			},
      ManagerComList:[],
      IP:null,
      Playerspc:null,
      videoContainerpc:null,
      isPlayingPlayers:false,
      isPlayingvideoContainer:false,
      isLoadingPlayers:false,
      isLoadingvideoContainer:false,
      currentTimePlayers:null,
      DeviceTable:[],
      selectPassChannelId:"",
      camerCall:{},
      isCamerCall:false,
      trtcData:{},
      client:null,
      heartSend:null,
      passCallData:{},
      falsePassViedo:false,
      callTime:5,  //呼叫倒计时
      callTimeFun:null,
      isCallTime:false,
      localStream:null,
      videoContainerData:{},
      cameraData:{},
      talkCameraData:{},
      allcandidateList:[],
      candidateList:[],
      orderText:'',
      passIn:false,
      passOut:false,
      loading1:false,
      loading2:false,
      selectPassData:{},
      refreshToken:null,
      wsToken:'',
      iceServers:[],
      selectPassChannelId2: null
    };
  },
  created() {
    this.queryParkList();
  },
  mounted() {
    document.querySelector(".audio").pause()
    // this.IP = localStorage.getItem('IP')
    this.IP = returnCitySN['cip'];
    // this.queryManagerComListFun();
    webRTCConfig().then(res=>{
      if(res.length){
        res.map(item=>{
          let obj1 = {urls: `stun:${item}`}
          let obj2 = {
            "url":`turn:${item}`, 
            "credential":"webrtc", 
            "username":"admin"
          }
          this.iceServers.push(obj1)
          this.iceServers.push(obj2)
        })
      }
      // console.log(this.iceServers)
    })
    this.getWebSocket();
    this.getAlarmInfor();
    //刷新token
    this.refreshToken = setInterval(()=>{
      console.log('刷新token')
      refreshToken().then(res=>{

      })
    },600000)
    
  },
  destroyed() {
    let that = this;
    clearInterval(that.heartSend)
    this.heartSend = null;
    clearInterval(that.refreshToken)
    this.refreshToken = null;
    this.websock.close(); //离开路由之后断开websocket连接
  },
  methods: {
    // 关闭回调
    closeDialogTitle() {
      this.selectPassChannelId2 = null
      this.form.switchCarNumber = ''
      this.keyboardStart13 = 'text'
      this.dialogData = false
    },
    keyBoardFn13(val) {
      this.form.switchCarNumber = this.form.switchCarNumber.concat(val)
      if (this.form.switchCarNumber.length > 0) {
        
        this.keyboardStart13 = "num";
      } else {
        this.keyboardStart13 = "text";
      }
    },
    keyBoardFn133() {
      if (this.form.switchCarNumber.length > 0) { 
        this.keyboardStart13 = "num";
      } else {
        this.keyboardStart13 = "text";
      }
    },
    keyBoardFn11(val) {
      this.formInline.car_number = this.formInline.car_number.concat(val)
      if (this.formInline.car_number.length > 0) {
        
        this.keyboardStart11 = "num";
      } else {
        this.keyboardStart11 = "text";
      }
      this.getcarNumList(this.formInline.car_number)
    },
    keyBoardFn111() {
      if (this.formInline.car_number.length > 0) { 
        this.keyboardStart11 = "num";
      } else {
        this.keyboardStart11 = "text";
      }
    },
    keyBoardFn122() {
      if (this.manualSetCarNumberFrom.carNumber.length > 0) { 
        this.keyboardStart12 = "num";
      } else {
        this.keyboardStart12 = "text";
      }
    },
    keyBoardFn12(val) {
      this.manualSetCarNumberFrom.carNumber = this.manualSetCarNumberFrom.carNumber.concat(val);
      if (this.manualSetCarNumberFrom.carNumber.length > 0) {
        this.keyboardStart12 = "num";
      } else {
        this.keyboardStart12 = "text";
      }
    },
    getcarNumList(carNum) {
      if (this.selectParkId) {
        queryParkCarNumber({
          comId: this.selectParkId,
          carNumber: carNum,
        }).then((res) => {
          let data = JSON.parse(res);
          this.carNumberAll2 = data.data
        });
      } else {
        this.$message({ showClose: true, message: "请选择车场", type: "error" });
      }
    },
    manualSetCarNumberShowFun() {
      if (!this.selectPass) {
        return false
      }
      this.manualSetCarNumberShow = true
    },
    // 查询特殊车辆类型
    specialVehicleTypeFun() {
      if (!this.selectPass) {
        return false
      }
      specialVehicleType().then( res => {
        this.specialVehicleList = []
        for (var key in res) {
          this.specialVehicleList.push({ label: res[key], value: +key})
        }
        this.specialVehicleShow = true
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    // 特殊车辆
    specialVehicleFun() {
      if (!this.selectPass) {
        return false
      }
      const { comid } = this.selectPassData
      let data = {
        "carType": this.specialVehicleFrom.carType,
        "comId": comid,
        "passId": this.selectPass,
        "type": 0
      }
      if (this.specialVehicleFrom.carType == 14) {
        data.refuseAmount = this.specialVehicleFrom.refuseAmount
      }
      specialVehicle(data).then(res=>{
        // console.log(res)
        this.$tip.apiSuccess('操作成功')
        this.specialVehicleShow = false
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    // 手动识别
    manualSetCarNumberFun() {
      if (!this.selectPass) {
        return false
      }
      if (!this.manualSetCarNumberFrom.carNumber) {
        this.$message({ showClose: true, message: '车牌号不能为空', type: "error" })
        return false
      }
      // console.log(this.selectPass,this.selectPassData)
      const { comid } = this.selectPassData
      let data = {
        "carNumber": this.manualSetCarNumberFrom.carNumber,
        "comId": comid,
        "passId": this.selectPass,
        "plateColor": this.manualSetCarNumberFrom.plateColor
      }
      manualSetCarNumber(data).then(res=>{
        // console.log(res)
        this.$tip.apiSuccess('操作成功')
        this.manualSetCarNumberShow = false;
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    //修改车牌
    editCarNumber(){
      let that = this;
      this.$prompt('请输入修改后的车牌', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        that.order.carNumber = value;
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });       
      });
    },
    //重启设备
    restartDevice(item){
      let that = this;
      deviceRestart({sn:item.nodeId}).then(res=>{
        this.$tip.apiSuccess('操作成功')
        that.dialogTableVisible = false;
      })
    },
    //获取设备列表
    getDeciveList(passId){
      let that = this;
      queryDevice({comId:that.selectParkId,passId}).then(res=>{
        that.DeviceTable = res;
        that.dialogTableVisible = true
      }) 

    },
    //双击放大
    toFullScreen(id){
      let div = document.getElementById(id);
      let child = document.getElementById( id + '-video');
      if(div.style.width != '100vw'){
        div.style.position = "fixed";
        div.style.top = "0";
        div.style.left = "0";
        div.style.width = '100vw';
        div.style.height = '100vh';
        div.style.zIndex = '100';
        child.style.height = '100vh';
        if(id == "Players"){
          document.getElementById('videoContainer').style.zIndex = '1';
        }else{
          document.getElementById('Players').style.zIndex = '1';
        }
      }else{
        div.style.position = "relative";
        div.style.width = '100%';
        div.style.height = '100%';
        div.style.zIndex = '1';
        child.style.height = '40.4vh';
      }
      
    },
    queryManagerComListFun(){

      queryManagerComList().then(res=>{
        this.ManagerComList = res;
        
      })
    },
    getWebSocket() {
      let that = this;
      //初始化weosocket
      this.websock = new WebSocket(this.$websocket + getToken() + '/worktable');
      this.websock.onopen = this.websocketonopen;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
      this.wbStaus = true;

      
      //发送心跳
      this.heartSend = setInterval(()=>{
        console.log('发送心跳')
        this.websock.send('0x11')
      },30000)

      
    },
    websocketonopen(e) {
      //连接建立之后执行send方法发送数据
      // this.websock.send();
      console.log('链接成功')
      this.getWebSocketNum = 0
    },
    websocketonerror(e) {
      let that = this;
      
    },
    websocketonmessage(res) {
      //数据接收
      //登录
      let that = this;
      if(res.data != '0x12'){
        this.retData = JSON.parse(res.data);

      
        if(this.retData.service_name !== "onoff_gate"){
          this.passCallData = this.retData;
        }
        //登录获取ws_token
        if(this.retData.service_name == "login"){
          //替换新token
          if(this.retData.data.new_token){
            let Token = this.retData.data.new_token;
            setToken(Token)
            // that.websock.close()
            // that.getWebSocket()
          }
          that.wsToken = this.retData.data.ws_token;
        }
        //获取设备sdp
        if(this.retData && this.retData.service_name == "get_sdp"){
          // console.log('getSdp----------------------------',this.retData)
          // this.isLoadingvideoContainer = false;
        }
        if(this.retData && this.retData.service_name == "download_sdp"){
          // console.log('download_sdp',this.retData)
          if(this.retData.state == 1){
          
          }else{
            if(this.retData.state == 0){
              //对讲相机
              if(this.retData.data.connect_id == this.talkCameraData.connect_id){
                this.talkCameraData.sn = this.retData.data.sn;
                this.talkCameraData.connect_id = this.retData.data.connect_id;
                this.falsePassViedo = false;
                this.isTalk = true
                this.getVideo(that.retData.data.sdp,'Players');
                console.log(`第二步--------下行消息:对讲相机【${this.retData.data.connect_id}】平台download_sdp}`, this.retData.data)
              }else{
                //全景相机
                that.cameraData = that.retData.data
                this.isTalk = true
                that.getVideo(that.cameraData.sdp,'videoContainer')
                console.log(`第二步--------下行消息:全景相机【${this.retData.data.connect_id}】平台download_sdp}`, this.retData.data)
              }
              
            } else {
              console.log(`下行消息:对讲/全景【${this.retData.data.connect_id}】报错}`, this.retData.data)
            }
          }
        }
      
        //获取candidate
        if( this.retData && this.retData.service_name == "download_candidate"){
          if(this.retData.msg == "下发candidate"){
            if(this.retData.data.connect_id == this.talkCameraData.connect_id){
              // let list = that.retData.data.candidate.split(' ')
              // if (list.length !== 39) {
              //   this.candidateList.push(that.retData.data.candidate)
              // }
              this.candidateList.push(that.retData.data.candidate)
              console.log(`第三步---下行消息:对讲相机【${that.retData.data.connect_id}】平台下发candidate}`, this.retData.data)
              
              // that['PlayersPC'].addIceCandidate(new RTCIceCandidate(JSON.parse(that.retData.data.candidate)));
            }else{
              this.allcandidateList.push(that.retData.data.candidate)
              console.log(`第三步---下行消息:全景相机【${that.retData.data.connect_id}】平台下发candidate}`, this.retData.data)
              // that['videoContainerPC'].addIceCandidate(new RTCIceCandidate(JSON.parse(that.retData.data.candidate)));
            }
            
              
          }
        }
        //微信对讲
        if(this.retData.service_name == "download_trtc_callmsg" && this.retData.data.status == 3){
          this.retData.data.pass_name = this.retData.data.passName;
          this.retData.data.comid = this.retData.data.comName;
          this.retData.data.times = this.$getTime(this.retData.data.time);
          this.retData.data.isTrtcCall = true;
          this.trtcData = this.retData.data;
          this.tableData.push(this.retData.data)
          this.total = this.tableData.length;
          setTimeout(() => {
            this.playAudio()
          }, 1000)
        }
        //微信对讲已被接听
        if (this.retData.service_name == "download_trtc_callmsg" && this.retData.data.status == 9 && this.retData.msg == "通话已有其他人处理") {
          let data = this.retData.data;
          this.tableData.map((item,i)=>{
            if(item.requestNum ==this.retData.data.requestNum){
              this.tableData.splice(i,1);
              this.total = this.tableData.length;
            }
          })
        }
        if(this.retData.data && this.retData.data.service_name == "upload_callmsg"){
          if (this.retData.data.status === 2 || this.retData.msg === "相机主叫：已有其它管理员接听") {
            let delIndex = null
            this.tableData.map((item, tableIndex) => {
              if (item.call_id == this.retData.data.call_id) {
                delIndex == tableIndex
              }
            })
            this.tableData.splice(delIndex, 1)/
            this.$message({ message: this.retData.msg, type: "error" });
              return false
          }
          this.tableData.map((item,i)=>{
            if(item.call_id == this.retData.data.call_id){
              this.tableData.splice(i,1)
              this.total = this.tableData.length;
            }
          })
        }
        //微信对讲用户取消
        if (this.retData.service_name == "download_trtc_callmsg" && this.retData.data.status == 6 && this.retData.msg == "用户取消连接") {
          let data = this.retData.data;
          this.closeAudio()
          this.tableData.map((item,i)=>{
            if(item.requestNum ==this.retData.data.requestNum){
              this.tableData.splice(i,1);
              this.total = this.tableData.length;
            }
          })
        }
        //微信对讲用户超时
        if (this.retData.service_name == "download_trtc_callmsg" && this.retData.data.status == 8 && this.retData.msg == "超时未接听") {
          let data = this.retData.data;
          this.tableData.map((item,i)=>{
            if(item.call_id == this.retData.data.call_id){
              item.outTimeCall = true
              this.$set(this.tableData,i,item)
              this.closeAudio()
            }
          })
        }
        if (this.retData.state !== undefined) {
          //被动没有状态返回
          if (this.retData.state == 0) {
            //状态为 0
            if (this.retData.service_name == "login") {
              this.service_name = res.data.service_name;
              this.ws_token = res.data.ws_token;
            }
            // 呼叫上行
            if (
              this.retData.service_name == "upload_callmsg" &&
              this.retData.msg_id == this.reg
            ) {
              if(this.retData.data.status == 0){
                this.$message({
                  showClose: true,
                  message: this.retData.msg,
                  type: "success",
                });
                this.talkCameraData = Object.assign(this.talkCameraData,this.retData.data)
              }else{

                this.$message({
                  showClose: true,
                  message: this.retData.msg,
                  type: "success",
                });
              }
              
            }
            // 开闸放行、补单缴费、
            if (
              this.retData.service_name == "onoff_gate" ||
              this.retData.service_name == "inpark_release" ||
              this.retData.service_name == "settlement_with_out" ||
              this.retData.service_name == "makeup_order_with_pay"
            ) {
              if (this.retData.state === 0) {
                this.$alert("消息下发成功", "请确定", {
                  confirmButtonText: "确定",
                });
              } else {
                this.$alert(this.retData.msg, "请确定", {
                  confirmButtonText: "确定",
                });
              }
            }


            //主相机呼叫
            if (
              this.retData.service_name == "upload_callmsg" && this.retData.data.call_type != 3 && 
              this.retData.msg_id != this.reg && this.retData.msg != "web主叫：挂断--成功" && this.retData.msg !='相机主叫--完成呼叫：相机已挂断'
            ){
              this.$message({
                showClose: true,
                message: this.retData.msg,
                type: "success",
              });
              this.talkCameraData = Object.assign(this.talkCameraData,this.retData.data)
              if(this.retData.msg != '相机主叫--处理呼叫：处理呼叫成功'){
                if(this.retData.data.status == 0 && this.retData.data.call_id){
                  let obj = this.retData.data;
                  this.talkCameraData.connect_id = obj.connect_id;
                  obj.times = this.$getTime(obj.time);
                  this.tableData.push(obj);
                  this.total = this.tableData.length;
                  console.log('相机主叫--处理呼叫：处理呼叫成功',this.retData)
                  this.playAudio();
                }
                  //呼叫超时
                  if(this.retData.data.status == 3){
                    this.tableData.map((item,i)=>{
                      if(item.call_id == this.retData.data.call_id){
                        item.outTimeCall = true
                        this.$set(this.tableData,i,item)
                        this.closeAudio()
                      }
                    })
                  }
                }
            }
            
            if(this.retData.service_name == 'download_unprocessed_call'){
              const listData = this.retData.data;
              this.total = listData.length;
              listData.map((item,i) => {
                item.times = this.$getTime(item.time);
                // this.$set(this.tableData,i,item)
                this.tableData.push(item)
              });
            }
          } else {
            if (this.retData.state === 100017) {
              //登录过期,关闭当前页面
              this.$confirm(this.retData.msg, "提示", {
                confirmButtonText: "确定",
                showCancelButton: false,
                type: "warning",
              }).then(() => {
                window.opener = null;
                window.open("", "_self");
                window.close();
              });
            } else {
              if(this.retData.service_name != "get_sdp"){
                this.$message({
                  showClose: true,
                  message: this.retData.msg,
                  type: "error",
                });
              }
              
            }
            if(this.retData.service_name == 'upload_callmsg' && this.retData.msg == 'web主叫:获取sdp失败'){
              this.falsePassViedo = true;
            }
          }
        } else {
          //呼叫下行
          if (this.retData.msg_type == "down_callmsg") {
            if (this.retData.data.status === 0) {
              this.short_id = this.retData.data.short_id;
              this.isfloat = true;
              this.downCallmsg = this.retData.data;
              this.time = this.$getTime(this.retData.data.time);
              this.getParkPassData(
                this.retData.data.comid,
                this.retData.data.caller_sn
              ).then((res) => {
                this.company_name = res.data.company_name;
                this.pass_name = res.data.pass_name;
              });
              setTimeout(() => {
                this.isfloat = false;
              }, 20000);
            } else {
              if(this.retData.data.service_name == "upload_callmsg"){
                if (this.retData.data.status === 2 || this.retData.msg === "相机主叫：已有其它管理员接听") {
                  let delIndex = null
                  this.tableData.map((item, tableIndex) => {
                    if (item.call_id == this.retData.data.call_id) {
                      delIndex == tableIndex
                    }
                  })
                  this.tableData.splice(delIndex, 1)
                  this.$message({
                    message: this.retData.msg,
                    type: "error",
                  });
                  return false
                }
                this.tableData.map((item,i)=>{
                  if(item.call_id == this.retData.data.call_id){
                    this.tableData.splice(i,1)
                    this.total = this.tableData.length;
                  }
                })
              }
              //当status=1、2时，web端需要关闭对应的呼叫
              this.isfloat = false;
            }
          }
          if (this.retData.msg_type == "down_callmsgs") {
            const listData = this.retData.data;
            this.total = listData.length;
            listData.map((item,i) => {
              item.times = this.$getTime(item.time);
              // this.$set(this.tableData,i,item)
              this.tableData.push(item)
            });
            // this.$forceUpdate();
            // this.tableData = JSON.parse(JSON.stringify(listData));

            
          }
          // 告警消息通知
          if (this.retData.service_name === "alarm") {
            this.getAlarmInfor();
            const alarmData = this.retData.data;
            this.$alert(`
                  <div style="font-weight:600">通道名称：${alarmData.channel_name}</div>
                  <div style="font-weight:600">触发源：${alarmData.device_type}</div>
                  <div style="font-weight:600">类型：${alarmData.alarm_content}</div>
                  <div style="font-weight:600">告警级别：${alarmData.alarm_level}</div>
                  <div style="font-weight:600">告警时间：${alarmData.alarm_time}</div>
                `,{
              title: "告警消息通知",
              type: "warning",
              duration: 0,
              dangerouslyUseHTMLString: true,
              customClass: 'alarm-popper'
            });
          }
        }
        
      }
      
      // console.log(this.retData, "this.retData");
      
      

  
    },
    websocketclose(e) {
      let that = this
      this.wbStaus = false
      //关闭
      console.log("断开连接", e)
      //连接建立失败重连
      clearInterval(that.heartSend)
      this.heartSend = null
      setTimeout(() => {
        
        if (that.getWebSocketNum < 5) {
          that.getWebSocket()
          that.getWebSocketNum += 1
        }
      }, 5000)
    },
    //选中车场
    checkPark(park){

    },
    //选中通道
    checkPass(pass){
      
    },
    //微信对讲创建视频
    //创建视频
    createClientWX(){
      let that = this;
      
      getUSAndSI({userId:'admin_' + this.$store.state.userInfo.id}).then((res)=>{
        this.isTalk = true;
        let data = JSON.parse(res);
        this.trtcData.sdkAppID = +data.sdkId;
        this.trtcData.userSig = data.userSig;
        this.trtcData.wsToken = this.wsToken;
        console.log(that.trtcData,data)
        this.notifyingUserJoin();
        
      })

    },
    //通知用户进房
    notifyingUserJoin(){
      let that = this;
      this.client = TRTC.createClient({  
        mode: 'rtc',
        sdkAppId:that.trtcData.sdkAppID,
        // sdkAppId:1400582908,
        userId: 'admin_' + this.$store.state.userInfo.id,
        userSig:that.trtcData.userSig,
      });
      let roomId = Number(that.trtcData.passId)
      that.getlocalStream();
      that.client
        .join({roomId:roomId})
        .catch(error => {
          console.error('进房失败 ' + error);
        })
        .then((res) => {
          TRTC.getMicrophones().then(res=>{
            console.log(res)
          })
          that.pushlocalStream();
          let data = {
            requestNum:that.trtcData.requestNum,
            b:true,
            wsToken:that.wsToken,
            // userId: '1_' + this.$store.state.userInfo.id,
          }
          notifyingUserJoin(data).then((res)=>{
            
          })
          
          console.log('进房成功',roomId);
        });
      console.log(this.trtcData)
      
      
    },
    //创建本地音视频流
    getlocalStream(){
      let that = this;
      this.localStream = TRTC.createStream({ userId:'admin_' + that.trtcData.userID, audio: true, video: false });
      this.localStream
      .initialize()
      .catch(error => {
        console.log('初始化本地流失败 ' + error);
      })
      .then(() => {
        
        that.subscribe()
        console.log('初始化本地流成功');
      });
    },
    //发布本地音视频流
    pushlocalStream(){
        let that = this;
        that.client
        .publish(this.localStream)
        .catch(error => {
            console.log('本地流发布失败 ' + error);
        })
        .then(() => {
          console.log('发布成功 ');
        });
    },
    //订阅远端流
    subscribe(){
      console.log('订阅远端流')
      let that = this;
      that.client.on('stream-added', event => {
        that.isCall = true;
        const remoteStream = event.stream;
        console.log('远端流增加: ' + remoteStream.getId());

        //订阅远端流
        that.client.subscribe(remoteStream);
      });
      that.client.on('stream-subscribed', event => {
        const remoteStream = event.stream;
        console.log('远端流订阅成功：' + remoteStream.getId());

        // 播放远端流
        remoteStream.play('Players');
        remoteStream.on('player-state-changed', function(event){
          if(event.reason == "ended" && event.type == "audio"){
            that.stopCall()
            if (document.getElementById('Players')) {
              document.getElementById('Players').innerHTML="";
            }
            that.$message({message:"通话已挂断",type: "error"});
          }
        });
      });
    },
    //挂断
    stopCall(){
      
      // this.isCall = false;
      this.client.unpublish(this.localStream).then(() => {
        // 取消发布本地流成功
      });
      this.client.on('stream-added', event => {
        const remoteStream = event.stream;
        // 拒绝接收该远端流所包含的任何音视频数据
        this.client.unsubscribe(remoteStream).catch(e => {
          console.error('failed to unsubscribe remoteStream');
        });
      });
      this.leaveRoom();
    },
    //退房
    leaveRoom(){
      // this.isCall = false;
      if (this.client != null) {
        this.client
        .leave()
        .then(() => {
          document.getElementById('Players').innerHTML="";
          document.getElementById('videoContainer').innerHTML = '';
          this.isTalk = false;
          // 退房成功，可再次调用client.join重新进房开启新的通话。
        })
        .catch(error => {
          console.error('退房失败 ' + error);
          // 错误不可恢复，需要刷新页面。
        });
      }
    },
    // 接听
    answer(type) {
      this.closeAudio()
      const item = this.downCallmsg;
      this.parkAll.map((v) => {
        if (v.id == item.comid) {
          v.color = true;
          this.getParkPass(item.comid);
        }
      });
      let ret = Math.ceil(Math.random() * 10000000000000000);
      let time = new Date().getTime();
      this.passiveReg = ret + "" + time;
      const { call_id, caller_sn, comid } = this.downCallmsg;
      let called_sn = "web" + this.$store.state.userInfo.id;
      let paramsData = {
        comid,
        called_sn,
        call_id:call_id,
        caller_sn:caller_sn,
        service_name: "upload_callmsg",
        msg_id: this.passiveReg,
        time: time / 1000,
        call_type: 0,
        status: 1,
      };
      this.websock.send(
        JSON.stringify({
          data: paramsData,
          sign: this.$md5(paramsData + "").toUpperCase(),
        })
      );
      this.isfloat = false;
    },
    // 待处理接听
    answerInformation(item,i) {
      if(this.isTalk){
        return false
      }
      if(item.outTimeCall){
        this.tableData.splice(i,1)
        this.total = this.tableData.length;
        return false;
      }
      this.closeAudio()
      
      if(item.isTrtcCall){
        this.createClientWX()
        this.tableData.splice(i,1)
        this.total = this.tableData.length;
        this.camerCall = item;
        this.getPassVideoUrl(this.camerCall.passId,'videoContainer',3);
      }else{
        let time = new Date().getTime();
        let ret = Math.ceil(Math.random() * 10000000000000000);
        this.getPassVideoUrl(item.pass_id,'videoContainer',3);
        //选中通道
        this.selectParkId = item.comid;
        queryParkPass({park_id:item.comid}).then((res) => {
          const passdata = [...res];
          passdata.map((pass) => {
            pass.color = false;
            if(pass.id == item.pass_id){
              this.switchItem = pass;
            }
          });
          this.parkPass = passdata;
          this.parkPasslist = this.parkPass;
          if (res.length == 0) {
            this.$message({
              showClose: true,
              message: "此车场未查询到通道",
              type: "error",
            });
          }
        });
        this.getParkPass(item.comid); //查询对应通道
        this.parkAll = this.parkAlllist;
        this.parkAll.map(park=>{
          if(park.id == item.comid){
            this.selectParkData = park;
          }
        })
        this.queryParkDetail(item.comid);
        this.selectPass = item.pass_id;
        
        this.selectPassChannelId = item.channel_id;
        console.log('选择通道', item)
        this.isCamerCall = true;
        let reg = ret + "" + time;
        let paramsData = {
          service_name: "upload_callmsg",
          msg_id: reg,
          call_id:item.call_id,
          called_sn:"web",
          caller_sn:item.caller_sn,
          time: time / 1000,
          call_type: 0,
          status: 1,
          comid:item.comid,
          ip:this.IP,
        };
        
        this.camerCall.msg_id = reg;
        this.camerCall.called_sn = "web";
        this.camerCall.caller_sn = item.caller_sn;
        this.camerCall.comid = item.comid;
        this.camerCall.call_id = item.call_id;
        this.camerCall.isTrtcCall = false;
        this.talkCameraData = this.camerCall;
        this.websock.send(
          JSON.stringify({
            data: paramsData,
            sign: this.$md5(paramsData + "").toUpperCase(),
          })
        );
        this.tableData.splice(i,1)
        this.total = this.tableData.length;
      }
    },
    //挂断通话
    breakCall(item){
      let that = this;
      document.getElementById('videoContainer').innerHTML = '';
      document.getElementById('Players').innerHTML = '';
      this.isTalk = false;
      this.allcandidateList = [];
      this.candidateList = [];
      if(!this.camerCall.isTrtcCall){
        const { nodeId,connectId} = this.DeviceData;
        let time = new Date().getTime();
        let paramsData = {};
        if(!this.falsePassViedo){
          if(this.isCamerCall){
          
            paramsData = {
              service_name: "upload_callmsg",
              msg_id: this.camerCall.msg_id,
              call_id:this.camerCall.call_id,
              caller_sn:this.camerCall.caller_sn,
              called_sn: this.camerCall.called_sn,
              time: time / 1000,
              call_type: 0,
              status: 2,
              comid:this.camerCall.comid,
              ip:this.IP,
            };
            if(this.camerCall.call_id){
              this.websock.send(
                JSON.stringify({
                  data: paramsData,
                  sign: this.$md5(paramsData + "").toUpperCase(),
                })
              );
            }
            
          }else{
            paramsData = {
              service_name: "upload_callmsg",
              msg_id: this.reg,
              call_id:this.talkCameraData.call_id,
              caller_sn:this.talkCameraData.caller_sn,
              called_sn: nodeId,
              time: time / 1000,
              call_type: 3,
              status: 2,
              comid:this.switchItem.comid,
              ip:this.IP,
              connectId:this.talkCameraData.connect_id
            };

            
            this.websock.send(
              JSON.stringify({
                data: paramsData,
                sign: this.$md5(paramsData + "").toUpperCase(),
              })
            );
          }
        }
        let arr = []
        if(this.talkCameraData.sn){
          arr.push({"sn":this.talkCameraData.sn, "connectId":this.talkCameraData.connect_id})
        }
        if(this.cameraData.sn){
          arr.push({"sn":this.cameraData.sn, "connectId":this.cameraData.connect_id})
        }
        let closed = {
          service_name: "close_connect",
          "array":arr
        }
        this.websock.send(
          JSON.stringify({
            data: closed,
            sign: this.$md5(closed + "").toUpperCase(),
          })
        );
        this.isTalk = false;


        this.callTime = 5; 
        this.isCallTime = true;
        this.callTimeFun = setInterval(this.callTimeFunDo,1000)
        if(this.Playerspc != null){
          this.Playerspc = close
          this.Playerspc = null
        }
        if(this.videoContainerpc != null){
          this.videoContainerpc = close
          this.videoContainerpc = null
        }

      }else{
        this.stopCall()
      }
    },
    //呼叫倒计时
    callTimeFunDo(){
      let that = this;
      this.isCallTime = true;
      this.callTime -= 1; 
      if(this.callTime == 0){
        this.isCallTime = false;
        clearInterval(that.callTimeFun);
        this.callTimeFun = null;
      }
    },
    // 主动呼叫
    activeCall(item) {
      console.log(item)
      if(this.isTalk){
        this.$message({ showClose: true, message: "请挂断通话后再操作", type: "error", });
        return false
      }
      this.switchPass(item)
      this.selectPass = item.id 
      this.switchItem = item;
      this.camerCall.isTrtcCall = false;
      document.getElementById('videoContainer').innerHTML = '';
      document.getElementById('Players').innerHTML = '';
      let dataDeviceInfor = {
        comId: item.comid,
        passId: item.id,
      }
      let haveDJ = false
      queryDevice(dataDeviceInfor).then(res=>{
        res.map((device,i)=>{
          if(device.nikeName == "LCD屏"){
   
          }
          if(device.nikeName == "对讲相机"){
            if(device.status == 0){
              this.falsePassViedo = false;
              this.isLoadingPlayers = true;
              this.DeviceData = device
              let time = new Date().getTime();
              this.talkCameraData = device;
              const { nodeId } = this.DeviceData;
              let ret = Math.ceil(Math.random() * 10000000000000000);
              this.reg = ret + "" + time;
              let paramsData = {
                service_name: "upload_callmsg",
                msg_id: this.reg,
                called_sn: nodeId,
                time: time / 1000,
                call_type: 3,
                status: 0,
                comid:item.comid,
                ip:this.IP
              };
              this.isTalk = true;
              console.log(`上行消息:对讲相机【${paramsData.called_sn}】页面上传sdp`,paramsData)

              this.websock.send(
                JSON.stringify({
                  data: paramsData,
                  sign: this.$md5(paramsData + "").toUpperCase(),
                })
              );

            }else{
 
            }
            haveDJ = true
          }
          if(device.nikeName == "全景相机"){
            if(device.status == 0){
              this.isTalk = true;
              this.isLoadingvideoContainer = true;
              this.isCamerCall = false;
              this.getPassVideoUrl(this.selectPass,'videoContainer',3);
            }else{
              this.isLoadingvideoContainer = false;
              this.isCamerCall = true;
              // this.$message({
              //   showClose: true,
              //   message: "全景相机离线",
              //   type: "error",
              // });
            }
          }
        })
        if (!haveDJ) {
          res.map((device,i)=>{
            if(device.deviceType == 7001){
              if(device.status == 0){
                this.falsePassViedo = false;
                this.isLoadingPlayers = true;
                this.DeviceData = device
                let time = new Date().getTime();
                this.talkCameraData = device;
                const { nodeId } = this.DeviceData;
                let ret = Math.ceil(Math.random() * 10000000000000000);
                this.reg = ret + "" + time;
                let paramsData = {
                  service_name: "upload_callmsg",
                  msg_id: this.reg,
                  called_sn: nodeId,
                  time: time / 1000,
                  call_type: 3,
                  status: 0,
                  comid:item.comid,
                  ip:this.IP
                };
                this.isTalk = true;
                console.log(`上行消息:对讲相机【${paramsData.called_sn}】页面上传sdp`,paramsData)

                this.websock.send(
                  JSON.stringify({
                    data: paramsData,
                    sign: this.$md5(paramsData + "").toUpperCase(),
                  })
                );

              }else{
                this.falsePassViedo = true;
                this.isLoadingvideoContainer = false;
                this.$message({
                  showClose: true,
                  message: "相机离线",
                  type: "error",
                });
              }
              haveDJ = true
            }
          })
        
        }

      })  
      
      
    },
    //开闸放行
    open(type) {
      if(this.selectPass){
        const { comid,id } = this.selectPassData;
        let paramsData = {
          passId: id,
          comId:comid,
          type:type
        };
        onOffGate(paramsData).then(res=>{
          this.$message({
            showClose: true,
            message: "操作成功",
            type: "success",
          });
        })
      }
    },
    // 补单缴费
    addMoney() {
      if (this.switchPark !== {}) {
        this.dialogTitle = "补单缴费";
        this.dialogData = true;
      } else {
        this.$notify({ title: "警告", message: "请选择车场", type: "warning" });
      }
    },
    // 结算离场
    settlement() {
      if (this.switchPark !== {} && this.selectParkId) {
        this.dialogTitle = "结算离场";
        this.dialogData = true;
        this.selectPassChannelId2 = this.selectPassData.passtype == '0' ? '' : this.selectPassData.channelId
      } else {
        // this.$notify({ title: "警告", message: "请选择车场", type: "warning" });
      }
    },
    // 入场放行
    openInPark() {
      if (this.switchPark !== {} && this.selectParkId) {

        this.selectPassChannelId2 = this.selectPassData.passtype == '1' ? '' : this.selectPassData.channelId
        this.dialogTitle = "入场放行";
        this.dialogData = true;

      } else {
        // this.$notify({ title: "警告", message: "请选择车场", type: "warning" }); 
      }
    },
    // 确认操作
    confirmSumbit(formName) {
      if (!this.selectPassChannelId2 && this.selectPassChannelId2 != null) {
        this.$message({ showClose: true, message: "通道号不能为空", type: "error"})
        return false
      }
      if (!this.form.switchCarNumber) {
        this.$message({ showClose: true, message: "车牌号不能为空", type: "error"})
        return false
      }
      
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let paramsData = {};
          const { switchCarNumber } = this.form;
          console.log('this.selectPassData', this.selectPassData)
          paramsData = {
            comId: this.selectParkId,
            channelId: this.selectPassChannelId2,
            carNumber: switchCarNumber,
          };
          if (this.dialogTitle == "补单缴费") {
            makeupOrderWithPay(paramsData).then( res => {
              this.form.switchCarNumber = ''
              this.keyboardStart13 = 'text'
              this.$message({ showClose: true, message: "操作成功", type: "success",})
            }).catch( err => {
              this.$message({ showClose: true, message: err.message, type: "error"})
            })
          } else if (this.dialogTitle == "结算离场") {
            settlementWithOut(paramsData).then( res => {
              this.form.switchCarNumber = ''
              this.keyboardStart13 = 'text'
              this.$message({ showClose: true, message: "操作成功", type: "success"})
            }).catch( err => {
              this.$message({ showClose: true, message: err.message, type: "error"})
            })
          } else if (this.dialogTitle == "入场放行") {
            inParkRelease(paramsData).then( res => {
              this.form.switchCarNumber = ''
              this.keyboardStart13 = 'text'
              this.$message({ showClose: true, message: "操作成功", type: "success"})
            }).catch( err => {
              this.$message({ showClose: true, message: err.message, type: "error"})
            })
          }
          this.dialogData = false;
        } else {
          return false;
        }
      });
    },
    // 查询所有车场
    queryParkList() {
      queryParkList().then((res) => {
        let that = this
        const data = res;
        this.parkAll = [];
        this.parkAlllist = [];
        res.map(item=>{
          if (item.comPassList.length) {
            this.parkAllData[item.id] = item.companyName
            this.parkAll.push(item)
            this.parkAlllist.push(item)
          }
        })
        that.getPass(this.parkAll[0], 0);
        console.log('parkAll', this.parkAll)
      });
    },
    // 查询对应通道
    getPass(item, i) {
      if(item) {
        if(!this.isTalk) {
          this.switchPark = { ...item };
          this.selectParkId =  item.id;
          this.selectParkData =  item;
          this.selectPass = ''
          this.selectPassChannelId = '';
          this.selectPassChannelId2 = '';
          this.selectPassData = {}
          this.getParkPass(item, i); //查询对应通道
          this.queryParkDetail(item.id);
        }else{
          this.$message({
            showClose: true,
            message: "请挂断通话后再操作",
            type: "error",
          });
        }
      }
    },
    // 获取对应通道
    getParkPass(park,i) {
      console.log('获取对应通道', park)
      this.parkPass = park.comPassList;
      this.parkPasslist = park.comPassList;
    },
    // 获取通道信息
    switchPass(item) {
      console.log('获取通道信息', item)
      debugger
      if(!this.isTalk){
        this.selectPass = item.id;
        this.selectPassChannelId = item.channelId;
        this.selectPassChannelId2 = item.channelId;
        this.selectPassData = item   
        if(item.passtype == '0'){
          this.passIn = true;
          this.passOut = false;
        }else{
          this.passIn = false;
          this.passOut = true;
        }
      }else{
        this.$message({
          showClose: true,
          message: "请挂断通话后再操作",
          type: "error",
        });
      }

    },
    // 获取通道信息
    getPassInformation(comId, passId) {
      queryPassDetails({ token: getToken(),comId,passId}).then((res) => {
        const channel = { ...res };
        channel.channel_type = this.channelType[channel.channel_type];
        this.channelData = channel;
      });
    },

    // 获取通道视频
    getPassVideoUrl(item,id,type) {
      this['isPlaying' + id] = true;
      this['isLoading' + id] = true;
      // 关闭全景视频
      if(this[id + 'pc']){
        this[id + 'pc'].stop();
        this[id + 'pc'] = null;
        document.getElementById(id).innerHTML = '';
      }
      let data = {
        data:{
          "passId":item,
          "type":type,
          "ip":this.IP
        },
        "service_name": "get_sdp",
      }
      this.isCamerCall = true
      this.websock.send(JSON.stringify(data))
      console.log(`第一步----上行消息:全景相机【通道号:${data.data.passId}】页面get_sdp`,data)
    },
    async getVideo(res,id){
      // document.getElementById(id).innerHTML="";
      let that = this;
      this.isTalk = true;
      //Stun服务器地址
      let servers = {
        iceServers: that.iceServers,
        iceCandidatePoolSize:10,
        iceTransportPolicy:"all",
        bundlePolicy:"max-bundle"
      }
      that[id + 'PC'] = new RTCPeerConnection(servers);
      

      function onIceCandidate(pc, event) {
        if (event.candidate) {
          // 给相机发candidate的时候，带".local"和tcp的可以直接忽略掉不发送
          if(event.candidate.candidate.indexOf('.local') == -1 && event.candidate.candidate.indexOf('tcp') == -1){
            let uploadcandidatedata = {
              connectId:id == 'Players' ? that.talkCameraData.connect_id : that.cameraData.connect_id,
              sn:id == 'Players' ? that.talkCameraData.sn : that.cameraData.sn,
              candidate:event.candidate
            }
            let data = {
              data:uploadcandidatedata,
              "service_name": "upload_candidate",
            }
           console.log(`第五步-----上行消息:${id == 'Players' ? '对讲相机' : '全景相机'}【${that.talkCameraData.connect_id}】页面upload_candidate`,data)
            that.websock.send(JSON.stringify(data))
          }
        }
      }

      if (id == 'Players'){
        this.$message({
          showClose: true,
          message: '对讲前请检查麦克风和音频播放设备是否开启',
          type: "warning",
        });
        navigator.getUserMedia  = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia
        if(navigator.getUserMedia){
          await navigator.getUserMedia({video: false,audio: {echoCancellation: true, noiseSuppression:true}}, function onSuccess(stream) {
            // console.log('获取本地设备getUserMedia')
            const gumStream = stream
            for (const track of gumStream.getTracks()) {
              // console.log('发现设备getTracks',track)
              that[id + 'PC'].addTrack(track);
              // console.log('添加设备设备addTrack',track)
              setTimeout(()=>{
                that[id + 'PC'].addTransceiver("video");
                that[id + 'PC'].addTransceiver("audio");
                that[id + 'PC'].ontrack = function (event) {
                  // var parent = document.getElementById(id);
                  
                  if(document.getElementById(id + '-video')){
                    let removeObj = document.getElementById(id + '-video');
                    removeObj.parentNode.removeChild(removeObj)
                  }
                  
                  
                  var el = document.createElement(event.track.kind);
                  el.style.width = '100%';
                  el.style.height = '40.4vh';
                  el.style.objectFit = 'fill';
                  el.srcObject = event.streams[0];
                  el.autoplay = true;
                  el.id = id + '-video';
                  document.getElementById(id).appendChild(el);
                  if (el.nodeName === "VIDEO") {
                    el.oncanplay = () => {
                      
                      // 播放状态设置为true
                      // console.log(id,'开始播放了')
                      
                      that.getVideoDuration(id);
                      
                    };
                  } else if (el.nodeName === "AUDIO") {
                    el.oncanplay = () => {
                    };
                  }
                };
                //添加来自stun服务器的ICE
                that[id + 'PC'].onicecandidate = function(event) {
                  // console.log('添加来自stun服务器的ICE   onicecandidate()')
                  onIceCandidate(that[id + 'PC'], event);
                }
                that[id + 'PC'].oniceconnectionstatechange = function(e) {
                  onIceStateChange(that[id + 'PC'], e);
                };

                function onIceStateChange(pc, event) {
                  
                  // if (pc && id == 'Players') {
                  //   console.log('对讲相机 ICE state: ' + pc.iceConnectionState);
                  //   console.log('ICE state change event: ', event);
                  // }
                }

                that[id + 'PC'].createOffer().then((offer) => {
                  // console.log('createOffer成功回调抛出的offer',offer)
                  offer.sdp = res;
                  offer.type = 'offer';

                  that[id + 'PC'].setRemoteDescription(offer,function(){

                    // console.log('setRemoteDescription成功回调抛出的offer',offer)
                    that[id + 'PC'].createAnswer().then(function( answer ) {
                      // console.log('createAnswer成功回调抛出的answer',answer)
                      that[id + 'PC'].setLocalDescription( answer,function(){

                        // console.log('setLocalDescription成功回调抛出的answer',answer)

                      })
                      let uploadSpddata = {
                        connectId:id == 'Players' ? that.talkCameraData.connect_id : that.cameraData.connect_id,
                        sn:id == 'Players' ? that.talkCameraData.sn : that.cameraData.sn,
                        sdp:answer.sdp + '',
                        type:0,
                        // "ip":that.IP
                      }
                      let data = {
                        data:uploadSpddata,
                        "service_name": "upload_sdp",
                      }
                      console.log(`第四步----上行消息:${id == 'Players ? ' ? '对讲相机' :'全景相机'}【${uploadSpddata.connectId}】页面upload_sdp`,data)
                      // console.log('发送sdp  upload_sdp')
                      that.websock.send(JSON.stringify(data))
                      setTimeout(()=>{
                        if(id == 'Players'){
                          // console.log('添加IceCandidate   addIceCandidate')
                          that.candidateList.map(item=>{
                            that[id + 'PC'].addIceCandidate(new RTCIceCandidate(JSON.parse(item)));
                            console.log(`第六步-----对讲相机【${uploadSpddata.connectId}】页面配置平台candidate(addIceCandidate)`,item)
                          })
                        }else{
                          that.allcandidateList.map(item=>{
                            that[id + 'PC'].addIceCandidate(new RTCIceCandidate(JSON.parse(item)));
                            console.log(`第六步-----全景相机【${uploadSpddata.connectId}】页面配置平台candidate(addIceCandidate)`,item)
                          })
                        }
                      },2000)
                      
                    })
                  })
                })
              },1000)
            }
          })
        }else{
          this.$message({
            showClose: true,
            message: '请检查麦克风和音频播放设备是否链接',
            type: "error",
          });
        }
	    }else{
        that[id + 'PC'].addTransceiver("video");
        that[id + 'PC'].addTransceiver("audio");
        that[id + 'PC'].ontrack = function (event) {
          // var parent = document.getElementById(id);
          if(document.getElementById(id + '-video')){
            let removeObj = document.getElementById(id + '-video');
            removeObj.parentNode.removeChild(removeObj)
          }
          
          
          var el = document.createElement(event.track.kind);
          el.style.width = '100%';
          el.style.height = '40.4vh';
          el.style.objectFit = 'fill';
          el.srcObject = event.streams[0];
          el.autoplay = true;
          el.id = id + '-video';
          document.getElementById(id).appendChild(el);
          if (el.nodeName === "VIDEO") {
            el.oncanplay = () => {
              
              // 播放状态设置为true
              // console.log(id,'开始播放了')
              
              that.getVideoDuration(id);
              
            };
          } else if (el.nodeName === "AUDIO") {
            el.oncanplay = () => {
            };
          }
        };
        //添加来自stun服务器的ICE
        that[id + 'PC'].onicecandidate = function(event) {
          onIceCandidate(that[id + 'PC'], event);
        }
        that[id + 'PC'].oniceconnectionstatechange = function(e) {
          onIceStateChange(that[id + 'PC'], e);
        };

        function onIceStateChange(pc, event) {
          
          // if (pc && id == 'Players') {
          //   console.log('对讲相机 ICE state: ' + pc.iceConnectionState);
          //   console.log('ICE state change event: ', event);
          // }
        }

        that[id + 'PC'].createOffer().then((offer) => {
          // that.isLoading = false;
          offer.sdp = res;
          offer.type = 'offer';
          that[id + 'PC'].setRemoteDescription(offer,function(){
            that[id + 'PC'].createAnswer().then(function( answer ) {
              that[id + 'PC'].setLocalDescription( answer,function(){

                

              })
              // console.log(id,'本地sdp-----------------------------------------',answer.sdp)
              let uploadSpddata = {
                connectId:id == 'Players' ? that.talkCameraData.connect_id : that.cameraData.connect_id,
                sn:id == 'Players' ? that.talkCameraData.sn : that.cameraData.sn,
                sdp:answer.sdp + '',
                type:0,
                // "ip":that.IP
              }
              let data = {
                data:uploadSpddata,
                "service_name": "upload_sdp",
              }
              
              that.websock.send(JSON.stringify(data))
              console.log(`第四步----上行消息:${id == 'Players ? ' ? '对讲相机' :'全景相机'}【${uploadSpddata.connectId}】页面upload_sdp`,data)
              setTimeout(()=>{
                if(id == 'Players'){
                  that.candidateList.map(item=>{
                    that[id + 'PC'].addIceCandidate(new RTCIceCandidate(JSON.parse(item)));
                    console.log(`第六步----上行消息:对讲相机【${uploadSpddata.connectId}】页面配号平台candidate(addTceCandidate)`,item)
                  })
                }else{
                  that.allcandidateList.map(item=>{
                    that[id + 'PC'].addIceCandidate(new RTCIceCandidate(JSON.parse(item)));
                    console.log(`第六步----上行消息:全景相机【${uploadSpddata.connectId}】页面配号平台candidate(addTceCandidate)`,item)
                  })
                }
              },2000)
              
            })
          })
        })
      }
      

    },
    getVideoDuration(id) {
      var video = document.getElementById(id + '-video')
      // console.log('222222',video)
      this.isTalk = true;
      this['isLoading' + id] = false;
      //  如果没有获取到视频元素
      if (!video) {
        return;
      }

      let that = this;
      
      video.addEventListener("timeupdate", () => {
        console.log(33333)
        
        that.currentTimePlayers = getTime(video.currentTime);
      });

      var getTime = function (time) {
        let hour =
          Math.floor(time / 3600) < 10
          ? "0" + Math.floor(time / 3600)
          : Math.floor(time / 3600);
        let min =
          Math.floor((time % 3600) / 60) < 10
          ? "0" + Math.floor((time % 3600) / 60)
          : Math.floor((time % 3600) / 60);
        var sec =
          Math.floor(time % 60) < 10
          ? "0" + Math.floor(time % 60)
          : Math.floor(time % 60);

        return hour + ":" + min + ":" + sec;
      };
    },
    // 通道视频
    playPassVideo(urlFlv) {
      let playerPass = new WasmPlayer(
        this.passUrl, //播放地址
        "Players",
        this.callbackFun, //事件回调
        {
          cbUserPtr: this, //自定义指针 (this的指向)
          decodeType: "auto", //解码类型;'soft',强制H265解码,
          openAudio: true, //是否打开音频
          BigPlay: true, //是否开启大的播放按钮
          Height: true, //播放器尺寸是否继承父盒子的
        }
      );
      playerPass.play(urlFlv, true);
    },
    // 搜索车场
    getParkData() {
      //id company_name
      const reg = /^\d{0,10}$/;
      if (reg.test(+this.parkname)) {
        //车场号搜索
        this.parkAll = [...this.parkAlllist];
        this.parkAll = this.parkAll.filter((item) =>
          (item.id + "").includes(this.parkname)
        );
        this.parkAll.map((item) => {
          item.color = false;
        });
        if (this.parkname == "") {
          this.parkAll = [...this.parkAlllist];
          this.parkAll.map((item) => {
            item.color = false;
          });
        }
        this.parkPass = [];
        this.parkPasslist = [];
      } else {
        //名称搜索
        this.parkAll = [...this.parkAlllist];
        this.parkAll = this.parkAll.filter((item) =>
          item.companyName.includes(this.parkname)
        );
        this.parkAll.map((item) => {
          item.color = false;
        });
        if (this.parkname == "") {
          this.parkAll.map((item) => {
            item.color = false;
          });
        }
        this.parkPass = [];
        this.parkPasslist = [];
      }
      this.selectParkId = ''
      this.getPass(this.parkAll[0]);
    },
    // 搜索通道
    getPassData() {
      this.parkPass = [...this.parkPasslist];
      this.parkPass = this.parkPass.filter((item) =>
        item.passname.includes(this.passid)
      );
    },
    // 搜索车牌
    searchData() {
      if(this.formInline.car_number.length < 3) {
        this.$message({
          showClose: true,
          message: "请输入至少三位车牌号",
          type: "error",
        })
        return false
      }
      if (this.selectParkId) {
        queryParkCarNumber({
          comId: this.selectParkId,
          carNumber: this.formInline.car_number,
        }).then((res) => {
          let data = JSON.parse(res);
          this.carNumberAll = data.data;
        });
      } else {
        this.$message({
          showClose: true,
          message: "请选择车场",
          type: "error",
        });
      }
    },
    // 查询车场信息
    getParkInformation(item) {
      
      //选中状态切换
      this.car_number = item;
      //查询订单
      queryOrderDetails({
        comId: this.selectParkId,
        carNumber: this.car_number,
      }).then((res) => {
        this.inPicURL = res.inPicURL;
        this.outPicURL = res.outPicURL;
        const data = res.order;
        data.createTime = this.$getTime(data.createTime);
        this.order = data;
        this.orderText = '';
        let orderObj = {
          0:0,
          1:0,
          2:0,
          3:0,
          4:0,
          5:0,
          6:0,
          7:0,
          8:0,
          9:0,
          10:0,
          11:0
        }
        if(this.order.ticket.length){
          let ticket = this.order.ticket;
          let money = 0;
          let moneyUnite = '元'
          let time = 0;
          let timeUnite = '元'
          let discountPercent = 0;
          ticket.map(item=>{
            orderObj[item.ticketType] += 1
            if(item.ticketType == 3){
              time += Number(item.duration)
              timeUnite = this.ticketData[item.ticketUnit]
            }
            if(item.ticketType == 5){
              money += Number(item.money)
              moneyUnite = this.ticketData[item.ticketUnit]
            }
            if(item.ticketType == 6){
              discountPercent = item.discount + '折'
            }
          })
          let arr = [4,7,8,10,3,9,11,5,6,0,1,2];
          let orderText = []
          arr.map(ar=>{
            if(orderObj[ar] > 0){
              let text = this.ticketTypeValue[ar] + orderObj[ar] + '张'
              if(ar == 6){
                text = this.ticketTypeValue[ar] + orderObj[ar] + '张,已打' + discountPercent
              }
              if(ar == 5){
                text = this.ticketTypeValue[ar] + orderObj[ar] + '张,合计' + Number(money + '') + moneyUnite
              }
              if(ar == 3){
                text = this.ticketTypeValue[ar] + orderObj[ar] + '张,合计' + Number(time + '') + timeUnite
              }
              orderText.push(text)
            }
          })
          this.orderText = orderText.join(';')
        }
        if(this.order.inPassid){
          
          if(!isNaN(parseInt(this.order.inPassid))){
            this.getPassDatailsFun(this.order.inPassid,'inPassid')
          }
          
        }
      });
    
      // 查询月卡
      queryMonthCardDetails({
        comId: this.selectParkId,
        carNumber: this.car_number,
      }).then((res) => {
        this.monthlyCard = res;
        this.monthlyCard.name = res.name
        if (this.monthlyCard.eTime !== "") {
          this.monthlyCard.eTimes = this.$getTime(this.monthlyCard.eTime);
        }
      });
    },
    //根据通道号查询通道信息
    getPassDatailsFun(passId,name){
      getPassDatails(passId).then(res=>{
        this.order[name] = res.passname;
      })
    },
    // // 获取车场信息
    queryParkDetail(park_id) {
      queryParkDetails({ token: getToken(), park_id }).then((res) => {
        this.parkDetails = res;
        if (this.parkDetails.companyName.length > 6) {
          this.parkDetails.companyName = this.parkDetails.companyName.slice(0,8);
        }
      });
    },

    //获取告警消息
    getAlarmInfor() {
      let params = {
        capacity: 80,
        page: 1,
        model:{
          status: 10001, //未处理
        }
      };
      alarmQueryInfor(params).then((res) => {
        this.loading = false;
        const data = res.records;
        this.alarm_InFor = data.map((item) => {
          item.levels = this.level[item.level];
          item.status = this.state[item.status];
          item.alarm_times = this.$getTime(item.alarm_time);
          return item;
        });
      });
    },
    // 确认处理
    confirmOperation(item) {
      let data = {
        id:item.id,
        status:10002
      }
      alarmOperateInfor(data).then((res) => {
        this.$message({
          showClose: true,
          message: res,
          type: "success",
        });
        this.getAlarmInfor();
      }).catch((e)=>{
        
        this.$tip.apiFailed(e)
      })
    },
    //播放铃声
    playAudio() {
      // console.log('播放铃声')
      const audio = document.querySelector(".audio");
      // console.log('播放铃声2')
      // // this.$refs.audio.src = require("../../assets/yunyin_web.wav");
      // console.log('播放铃声3')
      // audio.play() 
      
      let music1 = new Audio();
      music1 = require("../../assets/yunyin_web.wav");
      audio.src = music1;
      audio.play();
    },
    // 关闭铃声
    closeAudio() {
      const audio = document.querySelector(".audio");
      audio.pause();
    },
    // 鼠标按住浮动方块拖动
    moveFloatBlock(event) {
      window.onmousemove = function (e) {
        const floatBlock = document.querySelector(".floatBlock");
        floatBlock.style.left = e.clientX - event.offsetX + "px";
        floatBlock.style.top = e.clientY - event.offsetY + "px";
      };
    },
    // 结束拖动
    endDag() {
      window.onmousemove = function (e) {
        return "";
      };
    },
    // 关闭浮动块
    closeFloatBlock() {
      this.isfloat = false;
    },
    callbackFun(e) {},
    // 获取通道号车场信息
    async getParkPassData(park_id, sn) {
      let passData = queryPassData({ token: getToken(), park_id, sn });
      return passData;
    },
    //下载插件
    down() {
      window.location.href = this.$p2pUrl;
    },
    outImgError() {
      document.querySelector("#outImg").src = this.noPic;
    },
    inImgError() {
      document.querySelector("#inImg").src = this.noPic;
    },
  },
};
</script>
<style lang="scss" scoped>
// $fontColor:#606266;
$fontColor: #D0E6F7;
$borderColor: rgba(0, 143, 209, 0.6);
$line: #008fd1;
.big-form /deep/ .el-input--small .el-input__inner {
  height: 48px;
  line-height: 48px;
  width: 300px;
  font-size: 20px;
}
.big-form /deep/  .btn-div {
  line-height: 40px;
  text-align: center;
  font-size: 20px;
}
.big-form /deep/ .el-form-item__label {
  font-size: 20px;
  line-height: 48px;
}
.intercom{
  width:100vw;
  height:100vh;
  background-image: url("../../assets/images/bg.png");
  background-size: 100% 100%;
  background-position: center center;
  background-repeat:  no-repeat;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  // margin:15px;
}
.leftMoudle {
  width: 35.5vw;
  padding:20px;
}
.change-carNumber{
  cursor: pointer;
  i{
    margin-left:6px;
  }
}
.titles_02 {
  display: flex;
  justify-content: space-between;
  div {
    width: 49%;
  }
}
.park_pass,.titles {
  font-weight: 600;
  margin-bottom: 10px;
  color: $fontColor;
  font-size: 1.5vh;
}
.park_pass{
  font-size:1.7vh;
  color:#2aebff !important;
}
.park_pass:before{
  content:'';
  width:4px;
  height:1.7vh;
  background: #2aebff;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 10px;
}
.parking {
  border: 1px solid $borderColor;
  padding: 10px;
  box-shadow: 0 0 10px #4b8ef6 inset;
}
.parking_02 {
  padding: 20px;
  background-image: url("../../assets/images/sdxmLB.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.parking_03 {
  padding: 5px;
  border: 1px solid $borderColor;
  margin-bottom: 5px;
  box-shadow: 0 0 5px #4b8ef6 inset;
  
}
.checked-park,.blue{
  padding: 5px;
  margin-bottom: 5px;
  box-shadow: 0px 1px 43px 0px rgba(250, 212, 0, .1) inset;
  border: 1px solid rgba(250, 212, 0, 1);
  background: rgba(250, 212, 0, .15);
  cursor: pointer;
}
.checked-pass{
  box-shadow:0px 1px 43px 0px rgba(250, 212, 0, .1) inset;
  background: rgba(250, 212, 0, 0.15);
}
.blue{
  box-shadow: 0px 1px 100px 0px rgba(250, 212, 0, .1) inset;
  background: rgba(250, 212, 0, .15);
}
.parking_04 {
  height: 44vh;
  overflow-y: scroll;
}
.parking_05 {
  display: flex;
  justify-content: space-between;
}
.parking_05 > div {
  width: 49%;
}
.microphone {
  padding: 2px;
  cursor: pointer;
}
.microphone:hover {
  transform: scale(0.9);
}
.microphone > svg {
  width: 100%;
  height: 100%;
}
/deep/.searchPark {
  margin-bottom: 10px;
  div {
    input {
      background: #102563;
      border: 1px solid #008fd1;
      color: #fff;
    }
  }
}
.num {
  color: #F56C6C;
  font-size: 1.7vh;
  font-weight: 600;
}
.table {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.table > div {
  width: 49%;
  height: 35vh;
  overflow-y: scroll;
}
.data {
  // display: flex;
  // justify-content: space-between;
  width: 100%;
  color: $fontColor;
  font-size: 1.7vh;
  padding: 8px 10px;
  margin-bottom: 5px;
  box-shadow: 0 0 5px #4b8ef6 inset;
  border: 1px solid rgba(0, 143, 209, 0.6);
  box-sizing: border-box;
  cursor: pointer;
  height:11vh;
  animation:orangellowPulse 2s ease 0s;
  animation-iteration-count:infinite;
}
.alarm > div,
.data > div {
  line-height:2.3vh;
}
.alarm > div > span,
.data > div > span {
  color: $fontColor;
  font-weight: 600;
}
.infor {
  font-weight: 600;
  div {
    margin-bottom: 5px;
    span {
      color: #4b8ef6;
    }
  }
}
.context {
  width: 64%;
  padding: 20px;
}
.line {
  width: 100%;
  height: 5px;
  background: $line;
  margin-bottom: 10px;
}
.radios {
  margin-bottom: 3px;
}
/deep/.el-radio__label {
  color: $fontColor;
  font-weight: 600;
  font-size: 1.7vh;
}
/deep/.el-card__header {
  padding: 5px 10px !important;
  font-size: 1.4vh;
  color: $fontColor;
  line-height: 21px;
}
/deep/.el-textarea__inner {
  background-color: unset;
  border: 0;
  color: $fontColor;
  padding: 0;
}
/deep/.el-dialog__header {
  text-align: center;
}
/deep/#searchTitle > label {
  color: $fontColor;
  font-size: 1.8vh;
  font-weight: bold;
}
/deep/#searchTitle   div  div  input {
  background: #102563;
  border: 1px solid #008fd1;
  color: #fff;
}
/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #00cf6d;
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #00cf6d;
  background-color: #00cf6d;
}
/deep/.el-card {
  border: 1px solid rgba(0, 143, 209, 0.6);
  box-shadow: 0 0 10px rgba(75, 142, 246, 0.7) inset;
  border-radius: 2px;
}
/deep/.el-card__header {
  border-bottom: 1px solid rgba(0, 143, 209, 0.6);
}
.park_10 {
  font-size: 1.7vh;
  color: $fontColor;
}
.park_10 > div > div {
  margin-bottom: 1px;
}
.parkInformation {
  font-size: 1.7vh;
}
.parkInformation > div > div > span,
.state > div > span,
.park_10 > div > div > span {
  font-weight: 600;
}
.state {
  display: flex;
  justify-content: space-between;
}
.state > div > svg {
  width: 1.7vh;
  height: 1.7vh;
  vertical-align: middle;
  margin-right: 2px;
}
.order {
  // border: 1px solid $borderColor;
  font-size: 1.4vh;
  font-weight: 600;
  // box-shadow: 0 0 10px #4b8ef6 inset;
}
.order > div:first-child {
  margin-bottom: 10px;
  color: $fontColor;
}
.img {
  // display: flex;
  justify-content: space-between;
  width:22vw;
  box-sizing: border-box;
  margin-left:2vw;
}
.img > div {
  width: 22vw;
  height: 18vh;
  border: 1px solid $borderColor;
  box-sizing: border-box;
}
.img > div:first-child {
  margin-bottom:1vh;
}
.img > div > img {
  width: 100%;
  height: 100%;
}
.playVideo {
  // border: 1px solid $borderColor;
  margin-top: 1.6vh;
  display: flex;
  justify-content: space-between;
  font-size: 1.4vh;
  font-weight: 600;
  padding: 10px;
  // box-shadow: 0 0 10px #4b8ef6 inset;
  }
.switchPark-name {
  color: #D0E6F7 !important;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
  line-height: 40px;
  padding: 12px 0;
  box-sizing: border-box;
}
.playVideo1 {
  margin-top: 1vh;
  display: flex;
  justify-content: space-between;
  font-size: 1.4vh;
  font-weight: 600;
}
.input-with-select /deep/ .el-input__inner {
  width: 215px;
}
/deep/.el-card {
  background: unset;
}
.playVideo > div {
  width: 49.5%;
}
.DList{
  width:4vw;
  border:1px solid #67C23A;
  background:#67C23A;
  color:#ffffff;
  text-align: center;
  border-radius: 2px;
  padding: 4px 0px;
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 99;
}
.playVideo > div > div > svg {
  vertical-align: middle;
  height: 2.5vh;
}
.playVideo_02 {
  height: 40.4vh;
  border: 1px solid $borderColor;
}
.playVideo_02 > video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.floatBlock {
  position: absolute;
  width: 300px;
  right: 40px;
  top: 30%;
  box-shadow: 1px 1px 5px #4d4d4d;
  background-color: rgba(11, 49, 100, 1);
}
.floatBlock:hover {
  cursor: pointer;
}
.closeFloat {
  text-align: right;
}
.floatIn {
  font-size: 1.7vh;
  font-weight: 600;
  color: $fontColor;
}
.floatIn > div {
  margin-bottom: 10px;
}
.floatIn_02 {
  display: flex;
  justify-content: space-between;
}
.floatIn_02 > div:nth-child(2) {
  margin-left: 20px;
  margin: 5px 20px 0 0;
}
.parkInformation {
  padding: 10px;
  color: $fontColor;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  width:30vw;
  height:23vh;
}
// .parkInformation > div {
//   width: 50%;
// }
.parkInformation > div > div {
  margin-bottom: 5px;
}
.park_01 {
  color: $fontColor;
  padding: 5px 10px;
  font-size: 1.7vh;
  
  // position:relative;
}
.park_01 > div {
  margin-bottom: 10px;
}
.park_01 > div:last-child {
  margin-bottom: 0px;
}
.park_01 > div > span {
  font-weight: 600;
}
.phone {
  -webkit-animation: scale 2s linear infinite;
  -moz-animation: scale 2s linear infinite;
  -o-animation: scale 2s linear infinite;
  animation: scale 2s linear infinite;
}
.svg-icon {
  width: 1.8vh;
  height: 1.8vh;
  vertical-align: middle;
}
@keyframes scale {
  10% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(0.5);
  }
  70% {
    transform: scale(0.7);
  }
  90% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
::-webkit-scrollbar { 
  display: none;
}
*{
  scrollbar-width: none;
}
.btn-div{
  box-shadow: 0px 1px 43px 0px rgba(15, 203, 235, 0.4) inset;
  border: 1px solid rgba(15, 196, 229, 0.54);
  padding: 0 20px;
  cursor: pointer;
  color: #2aebff;
  border-radius: 2px;
  line-height:34px;
  text-align: center;
}
.btn-div-yellow{
  border: 1px solid rgba(250, 212, 0, 1);
  background:rgba(250, 212, 0, 1);
  padding: 0 20px;
  cursor: pointer;
  color: #fff;
  font-weight: bolder;
  border-radius: 2px;
  line-height:34px;
  text-align: center;
}
.btn-div-green{
  border: 1px solid #67C23A;
  background:#67C23A;
  padding: 0 20px;
  cursor: pointer;
  color: #fff;
  font-weight: bolder;
  border-radius: 2px;
  line-height:34px;
  text-align: center;
}
.playVideoBtn{
  display: flex;
}
.playVideoBtn>div{
  margin:0 10px;
}
.no_inPicURL,.no_outPicURL{
  color:rgba(0, 143, 209, 0.6);
  text-align:center;
  font-size:1.7vh;
}
.no_data_div{
  width:2.5vw;
  height:2.5vw;
  background-image: url("../../assets/images/no_data.png");
  background-size: 100% 100%;
  background-position: center center;
  background-repeat:  no-repeat;
  margin:0 auto;
  opacity:0.6;
  margin-top: 5vh;
}
.table-btn{
  position: relative;
  top: -6vh;
  div{

  }
}
.no-video{
  color:rgba(0, 143, 209, 0.6);
  text-align:center;
  font-size:1.7vh;
}
.no-video-img{
  width:2.5vw;
  height:2.5vw;
  background-image: url("../../assets/images/no_pass.png");
  background-size: 100% 100%;
  background-position: center center;
  background-repeat:  no-repeat;
  margin:0 auto;
  opacity:0.6;
  margin-top: 14vh;
  margin-bottom:1vh;
}
/deep/ .el-input-group__append {
  background-color: #102563;
  border: 1px solid rgba(15, 196, 229, 0.54);
}
/deep/ .el-popover{
  background: #757a86 !important;
  box-shadow: 0px 1px 43px 0px rgba(15, 203, 235, 0.4) inset !important;
}
.alarm-popper{
  background: #102563 !important;
  box-shadow: 0px 1px 43px 0px rgba(15, 203, 235, 0.4) inset !important;
}
.park-state-icon{
  position: relative;
  top: 11%;
  left: 13vw;
  width: 30px;
  text-align: center;
  i{
    font-size:2.4vh;
  }
}
.park_card_header{
  display: flex;
  color:#E6A23C;
  font-size:1.8vh;
  line-height:1.6vw;
}
.park_card_header_icon{
  width:1.6vw;
  height:1.6vw;
  background-image: url("../../assets/images/titleIcon.png");
  background-size: 100% 100%;
  background-position: center center;
  background-repeat:  no-repeat;
}
.park_detail_box{
  display: flex;
  width:100%;
}
.park_detail_box>div{
  flex-grow: 1;
}
.car_number_box{
  display: flex;
  width:100%;
  margin-left: 12px;
  margin-top:10px;
  flex-wrap: wrap;
  .car_number_item{
    width: 6vw;
    display: flex;
    font-size:1.7vh;
    color:#D0E6F7;
    white-space:nowrap;
    margin-right:30px;
    margin-bottom:10px;
    .no_checked{
      background-image: url("../../assets/images/xdCheckbox.png");
      background-size: 100% 100%;
      background-position: center center;
      background-repeat:  no-repeat;
      width:18px;
      height:18px;
      margin-right:5px;
    }
    .is_checked{
      background-image: url("../../assets/images/checked.png");
      background-size: 100% 100%;
      background-position: center center;
      background-repeat:  no-repeat;
      width:23px;
      height:18px;
    }
  }
}
.car_detail{
  // display: flex;
  width:100%;
  .vip_card_header{
    display: flex;
    color:#1BC6FB;
    font-size:1.8vh;
    line-height:1.6vw;
  }
  .vip_card_header_icon{
    width:1.6vw;
    height:1.6vw;
    background-image: url("../../assets/images/Bicon.png");
    background-size: 100% 100%;
    background-position: center center;
    background-repeat:  no-repeat;
  }
  .car_card_header_icon{
    width:1.6vw;
    height:1.6vw;
    background-image: url("../../assets/images/Gicon.png");
    background-size: 100% 100%;
    background-position: center center;
    background-repeat:  no-repeat;
  }
  .car_card_header{
    display: flex;
    color:#67C23A;
    font-size:1.8vh;
    line-height:1.6vw;
  }
}
.vip_card_box,.car_card_box{
  display: flex;
}
.vip_card_box>div{
  width:50%;
}
.vip_card_box>div,.car_card_box>div{
  flex-grow:1;
  margin-left: 12px;
  line-height: 1.6;
}
.park_card_box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.park_card_box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : #535353;
}
.park_card_box::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background   : #ededed;
}
.break-talk{
  width:40px;
  height:40px;
  border-radius: 50%;
  background:#F56C6C;
  position: fixed;
  bottom:3vh;
  right:30px;
  text-align: center;
  cursor: pointer;
  z-index:2001;
  i{
    line-height:40px;
    
  }
  .wb-staus{
    position: fixed;
    top: 20px;
    right: 20px;
    color: #d0e6f7;
  }
}
</style>
<style lang="scss">
  .wb-staus{
    position: fixed;
    top: 20px;
    right: 20px;
    color: #d0e6f7;
  }
  .alarm-popper{
    background: #102563;
    box-shadow: 0px 1px 43px 0px rgba(15, 203, 235, 0.4) inset;
    border: 1px solid rgba(15, 196, 229, 0.54);
    .popper__arrow{
      border-top-color: rgba(15, 196, 229, 0.54);
    }
    .popper__arrow::after{
      border-top-color: rgba(15, 203, 235, 0.4) !important;
    }
    .infor,.el-message-box__title,.el-message-box__message{
      color:#D0E6F7;

    }
  }
  .CarNumber-dialog{
    background: #102563 !important;
    box-shadow: 0px 1px 43px 0px rgba(15, 203, 235, 0.4) inset !important;
    border: 1px solid rgba(15, 196, 229, 0.54) !important;
    .el-dialog__title{
      color:#D0E6F7 !important;
      font-size: 22px;
    }
    .el-form-item__label{
      color:#D0E6F7 !important;
    }
    input{
      background: #102563 !important;
      border: 1px solid #008fd1 !important;
      color: #fff !important;
    }
    .btn-box{
      display: flex;
      justify-content:flex-end;
      
      .btn-div {
        font-size: 20px;
      }
      .cancel-btn{
        margin-right:1vw;
        box-shadow: 0px 1px 43px 0px rgba(255, 255, 255, 1) inset;
        border: 1px solid rgba(255, 255, 255, 1);
        padding: 0 20px;
        cursor: pointer;
        color:#000000;
      }
    }
    .el-table{
      background-color:rgba(255, 255, 255, 0);
      color:#D0E6F7;
      th,tr{
        background-color:rgba(255, 255, 255, 0);
        color:#D0E6F7;
      }
      tbody tr:hover>td { 
        background-color:rgba(255, 255, 255, 0);
        color:#D0E6F7;
      }
      th.is-leaf, td {
        border-bottom: 1px solid rgba(0, 143, 209, 0.6);
      }	
    }
    .el-table--group::after, .el-table--border::after, .el-table::before{
      background-color: rgba(0, 143, 209, 0.6);
    }
  }
  .intercom{
    .el-image{
      width:100% !important;
      height:100% !important;
    }
  }
  @-webkit-keyframes orangellowPulse {
    from {  -webkit-box-shadow: 0 0 9px #333 inset; }
    50% {  -webkit-box-shadow: 0 0 12px #ffb515 inset; border-color:#ffb515; }
    to {  -webkit-box-shadow: 0 0 9px #333 inset; }
  }
  
</style>